import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CryptoService {
    private secretKey = environment.secretKey;

    encrypt(text: string): string {
        const encrypted = CryptoJS.AES.encrypt(text, this.secretKey).toString();
        return encodeURIComponent(encrypted);
    }

    decrypt(text: string): string {
        const decodedText = decodeURIComponent(text);
        const bytes = CryptoJS.AES.decrypt(decodedText, this.secretKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
}
