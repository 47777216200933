import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalStateService } from '../../services/global-state.service';
import { DialogDownloadComponent } from 'src/app/components/dialog-download/dialog-download.component';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [DialogDownloadComponent],
  imports: [
    CommonModule,
    DialogModule,
    TooltipModule
  ],
  exports: [DialogDownloadComponent],
  providers: [GlobalStateService]
})
export class GlobalStateModule { }
