import { Injectable } from '@angular/core';
import { GerinusService } from '../lib/gerinus.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ISignDocumentGovBr } from 'src/app/interfaces/signature-gov.interfaces';

@Injectable({
  providedIn: 'root'
})
export class DocumentSignatureService extends GerinusService {

  constructor(public http: HttpClient) {
    super(http);
    this.entityName = "documento_assinante";
    this.model = "Assinante.Model";
    this.idField = 'DOCAS_CodigoDocumentoAssinante';
  }

  signatureAndSaveDocument(signatureData: ISignDocumentGovBr) {
    return this.http.post(this.apiURL() + '/api/app/sign-document', signatureData, this.headers())
  }

}
