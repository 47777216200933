import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { GerinusBD } from 'src/app/app-core/lib/gerinus-bd.component';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { ProcessService } from 'src/app/app-core/services/process.service';
import { DepartmentService } from 'src/app/app-core/services/department.service';
import { PersonService } from 'src/app/app-core/services/person.service';
import { ProccessHistoryService } from 'src/app/app-core/services/proccessHistorory.service';
import { UserService, UsersGroupEnum } from 'src/app/app-core/services/user.service';
import { RequestService } from 'src/app/app-core/services/request.service';
import { ProccessCommentService } from 'src/app/app-core/services/proccessComment.service';
import { CryptoService } from 'src/app/app-core/services/crypto.service';
import { MessageService } from 'primeng/api';

import swal from 'sweetalert2';
import { formatDistanceToNow } from 'date-fns';
import { da, id, ptBR } from 'date-fns/locale';

import { IProccess, ISubmitExtendProcessSchema, ISendRequesterConsiderationsSchema, IRequestToCancelProcessSchema, ICancellationEvaluationSchema, IProcessCorrections, IAddComplementaryFilesSchema } from 'src/app/interfaces/proccess.interface';
import { IDepartment } from 'src/app/interfaces/department';
import { IPerson } from 'src/app/interfaces/person';
import { IProcessComment } from 'src/app/interfaces/proccessComment';
import { IProccessHistory } from 'src/app/interfaces/proccessHistory';
import { IListScopeCommentsResponse } from 'src/app/interfaces/listScope/listScopeCommentResponse';
import { IListScopeHistoryProcessResponse } from 'src/app/interfaces/listScope/listScopeHistoryProccessResponse';
import { IListScopeDepartmentResponse } from 'src/app/interfaces/listScope/listScopeDepartmentResponse';
import { IGetEntityProccessResponse } from 'src/app/interfaces/getEntity/getEtityProccessResponse';
import { UploadService } from 'src/app/app-core/services/upload.service';
import { ProcessCorrectionService } from 'src/app/app-core/services/process-correction.service';
import Swal from 'sweetalert2';
import { IChangeRequestDescriptionSchema } from 'src/app/interfaces/request';
import { ProcessStatusEnum } from 'src/app/Enums/ProcessStatusEnum';
import { OrganizationService } from 'src/app/app-core/services/organization.service';
import { Observable } from 'rxjs';
import { typeProcessEnum } from 'src/app/Enums/typeProcessEnum';
import { OrganizationEnum } from 'src/app/Enums/organizationEnum';
import { FiscoService } from 'src/app/app-core/services/fisco.service';
import { DocumentService } from 'src/app/app-core/services/document.service';
import { GlobalStateService } from 'src/app/app-core/services/global-state.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { dispatch } from 'rxjs/internal/observable/pairs';

interface AutoCompleteCompleteEvent {
    originalEvent: Event;
    query: string;
}

@Component({
    selector: 'app-view-process',
    templateUrl: './view-process.component.html',
    styleUrls: ['./view-process.component.scss'],
    providers: [
        ProcessService,
        DepartmentService,
        PersonService,
        ProccessHistoryService,
        UserService,
        RequestService,
        ProccessCommentService,
        UploadService,
        ProcessCorrectionService,
        MessageService,
        OrganizationService,
    ],
})
export class ViewProcessComponent extends GerinusBD implements OnInit, AfterViewInit {
    public hasComplements: boolean = false;
    public process: IProccess;
    public swal = swal;
    public modalName: string = 'tramitar';
    public forDocuments = [
        {
            name: 'PROCURAÇÃO',
            type: 'procuracao',
        },
        {
            name: 'REQUERIMENTO',
            type: 'requerimento',
        },
        {
            name: 'COMPLEMENTARES',
            type: 'complementar',
        },
    ];
    public departments: IDepartment[];
    public persons: IPerson[];
    public comments: IProcessComment[] = [];
    public histories: IProccessHistory[] = [];
    public commentText: string = '';
    public USR_foto: string;
    public allowedFileMimeTypes: string[] = [
        'application/pdf',
        'application/x-zip-compressed',
        'application/x-rar-compressed',
        'application/vnd.rar',
        'application/zip',
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/bmp',
        'image/webp',
        'image/tiff',
        'image/svg+xml',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    public mimeTypePdf = ['application/pdf']
    public showDropzonePreview: boolean = true;

    public encryptedId: string;
    public tipoTramitacao: string;
    public responsibles: any = [];
    public selectedAddResponsible: any;
    public selectedToggleResponsible: any;
    public sidebarVisible: boolean = false;
    public reasonReturn: string;
    public daysAmountExtendsProcess: number;
    public editDescriptionAllowed: boolean = false;
    public isEditingDescription = false;
    public requesterConsiderations = '';
    public corrections: IProcessCorrections[];

    public organizations$: Observable<any>;
    public departamentName: string;
    public organizationId: number | null = null;
    public organizationName: string;
    public departamentsByOrg$: Observable<any>;

    public alreadyFilteredList = {}
    public modalResponsible: string = 'Rejeitar'
    isCancelProcessDialogOpen: boolean = false;
    isArchiveNoteModalOpen: boolean = false;
    responsibleRequest: any;
    public isLoading: boolean = false;
    public processId: number;
    public damId: string = '';
    public damData: any[] = [];
    public searchPerformed: boolean = false;
    public showDamTable: boolean = false;

    formattedDams: any[]
    isSearchDamModalOpen: boolean = false;

    public validStatus: number[] = [
        ProcessStatusEnum.ARCHIVED,
        ProcessStatusEnum.CONCLUDED,
        ProcessStatusEnum.OUTDATED
    ];
    public processAttachmentColumns = ["PA_CodigoAnexo", "PA_Nome", "PA_Anexo", "PA_Status", "created_at"]
    public processDispatchAttachmentColumns = ["PPD_CodigoParecerDespacho", "PPD_NomeAnexo", "PPD_Anexo", "PPD_Status", "created_at"]
    public processCertificateAttachmentColumns = ["PC_CodigoCertidao", "PC_Nome", "PC_Anexo", "PC_Status", "created_at"]
    public skeletronLoaded = {
        complementaryFiles: {
            show: false,
            items: 1
        },
        dispatch: {
            show: false,
            items: 1
        },
        certificate: {
            show: false,
            items: 1
        }
    }
    public complementaryFilesFromCorrection = [];

    constructor(
        public processService: ProcessService,
        public route: ActivatedRoute,
        public router: Router,
        public global: GlobalService,
        public departmentService: DepartmentService,
        public personService: PersonService,
        public processHistoryService: ProccessHistoryService,
        public userService: UserService,
        public requestService: RequestService,
        private commentService: ProccessCommentService,
        private cryptoService: CryptoService,
        private uploadService: UploadService,
        private messageService: MessageService,
        public organizationService: OrganizationService,
        private fiscoService: FiscoService,
        private globalStateService: GlobalStateService,
        private cd: ChangeDetectorRef 
    ) {
        super(global, processService);
        this.showEmptyData = false;
        this.showHeader = false;
        this.showPaginate = false;

        this.afterCreate();
        this.add();
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe((params) => {

            try {
                this.encryptedId = params.get('id');
                const processoId = Number(this.cryptoService.decrypt(this.encryptedId));
                this.processId = processoId;
                this.initializeData(processoId);

                if (!processoId) {
                    this.handleRedirect();
                }
            } catch (error) {
                this.handleRedirect();
            }
        });
    }

    ngAfterViewInit(): void {
        this.cd.detectChanges();
    }

    private handleRedirect(): void {
        if (this.userService.isTriagem()) {
            this.router.navigate(['/process/all'], { queryParams: { 'not-found': true } });
        }
        else if (this.userService.isDiretorDepartamento() || this.userService.isMembroDepartamento()) {
            this.router.navigate(['/process/sector'], { queryParams: { 'not-found': true } });
        }
        else if (this.userService.isCidadao()) {
            this.router.navigate(['/requests/processes'], { queryParams: { 'not-found': true } });
        }
        else if (this.userService.isAdmOrgao() || this.userService.isSecretario()) {
            this.router.navigate(['/process/organization'], { queryParams: { 'not-found': true } });
        }
    }

    private initializeData(processoId: number) {
        this.getProcess(processoId);
        this.getPerson();
        this.getHistories(processoId);
        this.getComments(processoId);
        this.searchResponsibles();
        this.getResponsibleRequests(processoId)
        this.getCorrections(processoId);
    }

    private get _haveDispatch(): boolean {
        return this.process.despachos && this.process.despachos.length > 0;
    }

    public defaults() {
        return {
            documentos: {
                parecerDespacho: [],
                correctionFiles: [],
                userId: this.global.user().USR_CodigoUsuario,
            },
            tramitacao: {
                PRCH_Historico: '',
                NTR_Descricao: '',
                NTR_Autor: ''
            },
            comentario: {
                COM_Descricao: '',
                COM_CodigoUsuario: this.global.user().USR_CodigoUsuario,
                COM_CodigoProcesso: '',
            },
            despacho: {
                PDES_Descricao: '',
                PDES_CodigoUsuario: this.global.user().USR_CodigoUsuario,
            },
            processAttachments: {
                complementaryFiles: [],
                userId: this.global.user().USR_CodigoUsuario,
            },
            processCertificates: {
                certificates: [],
                userId: this.global.user().USR_CodigoUsuario,
            },
            fixedAttachments: {
                documents: []
            },
            cancelProcess: {
                reasonCancel: '',
                evaluationDescription: ''
            },
            archive: {
                archiveNote: ''
            }
        };
    }

    getPerson() {
        this.personService
            .listAll(
                `
                    "PE_CodigoDepartamento" = ${this.global.user().PE_CodigoDepartamento} and
                    "PE_CodigoPessoa" != ${this.global.user().PE_CodigoPessoa}
                `,
                0,
            )
            .subscribe({
                next: (response: any) => {
                    this.persons = response.data;
                },
            });
    }

    getHistories(processoId: number) {
        this.processHistoryService
            .listAll(`"PRCH_CodigoProcesso" = ${processoId} order by "PRCH_Data" desc`)
            .subscribe({
                next: (response: IListScopeHistoryProcessResponse) => {
                    this.histories = response.data;
                },
            });
    }

    isClickInsideComponent(event: MouseEvent, componentName: string): boolean {
        const clickedComponent = event.target as HTMLElement;

        return (
            clickedComponent.classList.contains(componentName) || clickedComponent.closest(`.${componentName}`) !== null
        );
    }

    getComments(processId: number) {
        this.commentService
            .listAll(`"COM_CodigoProcesso" = ${processId} order by "COM_DataComentario" desc`, 0)
            .subscribe({
                next: (response: IListScopeCommentsResponse) => {
                    this.comments = response.data.map((comment) => {
                        return {
                            ...comment,
                            formattedDate: formatDistanceToNow(new Date(comment.COM_DataComentario), {
                                addSuffix: true,
                                locale: ptBR,
                            }),
                        };
                    });
                },
            });
    }

    getDepartments() {
        this.departmentService
            .listAll(
                `
                "DEP_CodigoOrgao" = ${this.process.SOL_CodigoOrgao} and
                "DEP_CodigoDepartamento" != ${this.process.PRT_DepartamentoAtual}
                order by "DEP_Descricao" asc
                `,
                0,
            )
            .subscribe({
                next: (response: IListScopeDepartmentResponse) => {
                    this.departments = response.data.filter(dep => 
                        dep.DEP_TipoProcessoAceito === 1 || dep.DEP_TipoProcessoAceito === 3
                    );
                },
                error: (error) => {
                    console.log('ERRO', error);
                },
            });
    }

    getProcess(id: number) {
        this.processService.getEntity(id).subscribe({
            next: async (response: IGetEntityProccessResponse) => {
                this.process = response.entity;
                this.process.PRC_DataRecebimento = new Date(this.process.PRC_DataRecebimento);
                this.process.PRC_DataRecebimento.setHours(this.process.PRC_DataRecebimento.getHours() + 3);
                // this.hasComplements = this.process.documentos.some(
                //     (documento) => documento.SOLA_Tipo == 'complementar',
                // );
                this.skeletonHidden();
                this.getDepartments();
                this.getPerson();

                document.querySelector('title').text = 'Processo N° ' + this.process.PRC_NumeroProcesso;
            },
            complete: () => {
                this.formatDamData()
            },
        });
    }

    formatDamData() {
        const damPromises = this.process.dam.map((dam) => {
            return new Promise((resolve) => {
                this.fiscoService.getDataDam(dam.PDAM_NumeroDam).subscribe({
                    next: (response: any) => {
                        if (response.success) {
                            const dam = {
                                contribuinte: response.data.contribuinte,
                                id: response.data.id,
                                link: response.data.link,
                                receita: response.data.receita,
                                situacao: response.data.situacao,
                                valor: response.data.valor,
                                vencimento: response.data.vencimento,
                            };
                            resolve(dam);
                        } else {
                            resolve(null);
                        }
                    },
                    error: () => {
                        resolve(null);
                    }
                });
            });
        });

        Promise.all(damPromises).then((dams) => {
            this.formattedDams = dams;
            console.log('PROCESS DAMs:', this.formattedDams )
            this.showDamTable = true;
        });
    }

    openExtendProcessModal(): void {
        this.global.showModalInfo('prorrogar-processo');
    }

    handleExtendsProcess(): void {
        const extendsProcessData: ISubmitExtendProcessSchema = {
            processId: this.process.PRC_CodigoProcesso,
            daysAmount: this.daysAmountExtendsProcess
        }

        this.processService.submitExtendsProcess(extendsProcessData).subscribe({
            next: (response: any) => {
                this.getProcess(this.process.PRC_CodigoProcesso);
                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: 'O processo foi prorrogado com sucesso.',
                });
                this.global.closeModalInfo('prorrogar-processo');
            }
        })
    }

    handleCompleteProcess(): void {
        if (!this._haveDispatch) {
            this.showMissingDispatchAlert('conclusao');
        } else {
            const completeProcessData = {
                PRCH_Historico: `${this.global.user().PE_Nome} concluiu o processo!`,
            };

            this.confirmProcessAction('conclusao', () => {
                this.processService
                    .markProcessAsCompleted(this.process.PRC_CodigoProcesso, completeProcessData)
                    .subscribe({
                        next: async () => {
                            await this.getProcess(this.process.PRC_CodigoProcesso);

                            this.messageService.add({
                                severity: 'success',
                                icon: 'ph ph-check-circle',
                                closable: true,
                                summary: 'Sucesso!',
                                detail: 'O processo foi marcado como concluído.',
                            });
                        },
                    });
            });
        }
    }

    handleArchiveProcess(): void {
        const archiveProcessData = {
            PRCH_Historico: `${this.global.user().PE_Nome} arquivou o processo!`,
            archiveNote: this.entity.archive.archiveNote
        };

        this.processService
            .markProcessAsArchived(this.process.PRC_CodigoProcesso, archiveProcessData)
            .subscribe({
                next: async () => {
                    await this.getProcess(this.process.PRC_CodigoProcesso);
                    this.isArchiveNoteModalOpen = false
                    this.messageService.add({
                        severity: 'success',
                        icon: 'ph ph-check-circle',
                        closable: true,
                        summary: 'Sucesso!',
                        detail: 'O processo foi marcado como arquivado.',
                    });
                },
            });
    }

    handleSaveDispatch() {
        this.processService.addDispatch(this.process.PRC_CodigoProcesso, this.entity.despacho).subscribe({
            next: () => {
                this.entity.despacho.PDES_Descricao = '';

                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: 'Novo despacho adicionado.',
                });

                this.getProcess(this.process.PRC_CodigoProcesso);
            },
        });
    }

    handleAddResponsible() {
        const addResponsibleData = {
            PRC_CodigoResponsavel: this.selectedAddResponsible.PE_CodigoPessoa,
            PRCH_Historico: `${this.global.user().PE_Nome} adicionou ${this.selectedAddResponsible.PE_Nome
                } como responsável do processo`,
        };

        this.processService.addResponsible(this.process.PRC_CodigoProcesso, addResponsibleData).subscribe({
            next: async () => {
                this.global.closeModalInfo('add-responsavel');
                await this.getProcess(this.process.PRC_CodigoProcesso);

                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: 'Um novo responsável por este processo foi designado.',
                });
            },
        });
    }

    handleToggleResponsible() {
        const toggleResponsibleData = {
            PRC_CodigoResponsavel: this.selectedToggleResponsible.PE_CodigoPessoa,
            PRCH_Historico: `${this.global.user().PE_Nome} alternou o responsável para ${this.selectedToggleResponsible.PE_Nome} no processo`,
        };

        this.processService.toggleResponsible(this.process.PRC_CodigoProcesso, toggleResponsibleData).subscribe({
            next: async () => {
                this.global.closeModalInfo('toggle-responsavel');
                await this.getProcess(this.process.PRC_CodigoProcesso);

                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: 'O responsável deste processo foi alternado com sucesso.',
                });
            },
            error: (err) => {
                this.messageService.add({
                    severity: 'error',
                    icon: 'ph ph-exclamation-circle',
                    closable: true,
                    summary: 'Erro!',
                    detail: 'Ocorreu um erro ao alternar o responsável. Tente novamente.',
                });
            }
        });
    }
    private showMissingDispatchAlert(action: 'arquivacao' | 'conclusao'): void {
        this.global.swal.fire({
            title: `Erro ao ${action === 'arquivacao' ? 'arquivar' : 'concluir'} Processo`,
            text: 'Você precisa de um despacho para concluir a ação',
            icon: 'error',
        });
    }

    private confirmProcessAction(actionField: 'arquivacao' | 'conclusao', action: () => void) {
        this.global.swal
            .fire({
                title: `Você deseja ${actionField === 'arquivacao' ? 'arquivar' : 'concluir'} o Processo?`,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Não',
                confirmButtonText: 'Sim',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    action();
                }
            });
    }

    joinOnProccess() {
        const joinOnProccessData = {
            PRCH_Historico: `O processo está em análise por ${this.global.user().PE_Nome}`,
            PRC_CodigoResponsavel: this.global.user().PE_CodigoPessoa,
        };

        this.processService.join(this.process.PRC_CodigoProcesso, joinOnProccessData).subscribe({
            next: async () => {
                this.getProcess(this.process.PRC_CodigoProcesso);

                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: 'Você agora é responsável pelo processo.',
                });
            },
        });
    }

    transmitProcess(type) {
        if (this.InvalidNote) {
            this.messageService.add({
                severity: 'error',
                icon: 'ph ph-x-circle',
                closable: true,
                summary: 'Erro!',
                detail: 'A nota de tramitação é obrigatória.',
            });
            return;
        }
        if (type === 'interna') {
            this.entity.tramitacao.PRCH_Historico = `${this.global.user().PE_Nome
                } tramitou para ${this.mappingPersons()} - Aceite pendente`;
        } else if (type === 'externa') {
            this.entity.tramitacao.PRCH_Historico = `${this.global.user().PE_Nome
                } tramitou para ${this.mappingDepartamento()}`;
        } else if (type === 'externa-orgao') {
            this.entity.tramitacao.PRCH_Historico = `${this.global.user().PE_Nome} tramitou para ${this.departamentName} do orgão ${this.organizationName}`;
        }

        this.entity.tramitacao.NTR_Autor = this.global.user().PE_CodigoPessoa;
        this.entity.tramitacao.PRC_DepartamentoTramitador = this.global.user().PE_CodigoDepartamento;
        this.entity.tramitacao.USR_CodigoUsuario = this.global.user().USR_CodigoUsuario;
        this.entity.tramitacao.PE_CodigoDepartamento = this.global.user().PE_CodigoDepartamento;
        this.global.startLoading('Tramitando...', true);
        this.processService.transmit(this.process.PRC_CodigoProcesso, this.entity.tramitacao).subscribe({
            next: async () => {
                this.global.closeModalInfo(this.modalName + '-add-departamento');

                if (type == 'externa') {
                    this.global.router.navigate(['/process/transferred']);
                }

                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: 'Um novo responsável por este processo foi designado.',
                });
                this.getProcess(this.process.PRC_CodigoProcesso);
                window.location.reload();
            },
        });
    }

    mappingDepartamento() {
        return this.departments.filter((departament) => {
            return departament.DEP_CodigoDepartamento == this.entity.tramitacao.PRC_CodigoDepartamentoAtual;
        })[0].DEP_Descricao;
    }

    public mappingPersons() {
        return this.persons.filter((person) => {
            return person.PE_CodigoPessoa == this.entity.tramitacao.PRC_CodigoResponsavel;
        })[0].PE_Nome;
    }

    public handleNavigationBackButton() {
        if (this.userService.isCidadao()) {
            this.global.router.navigate(['/requests/all']);
        } else {
            window.history.back();
        }
    }

    addComment() {
        if (this.commentText.trim() !== '') {
            this.entity.comentario.COM_Descricao = this.commentText;
            this.entity.comentario.COM_CodigoUsuario = this.global.user().USR_CodigoUsuario;
            this.entity.comentario.COM_CodigoProcesso = this.process.PRC_CodigoProcesso;
            this.entity.comentario.COM_DataComentario = new Date();
            this.entity.comentario.USR_foto = this.global.user().USR_foto;
            this.saveComment();
        }
    }

    private saveComment() {
        this.entity.comentario.COM_Descricao = this.commentText;
        this.commentService.save(this.entity.comentario).subscribe({
            next: (response: any) => {
                this.commentText = '';
                this.comments.unshift({
                    ...response.entity,
                    USR_foto: this.global.user().USR_foto,
                    formattedDate: formatDistanceToNow(new Date(response.entity.COM_DataComentario), {
                        addSuffix: true,
                        locale: ptBR,
                    }),
                });

                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: 'Novo comentário adicionado ao processo.',
                });
            },
        });
    }

    initCommentEdition(comentario: any) {
        comentario.editText = comentario.COM_Descricao;
    }

    handleSaveCommentEdition(comentario: any) {
        comentario.COM_Descricao = comentario.editText;

        this.commentService.save(comentario).subscribe({
            next: () => {
                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: 'O comentário foi alterado.',
                });
                this.global.closeModalInfo(this.modalName + comentario.COM_CodigoComentario);
            },
        });
    }

    getStatusClass(status: string): string {
        switch (status) {
            case '1':
                return 'badge-primary';
            case '2':
                return 'badge-success';
            default:
                return 'badge-default';
        }
    }

    getUploadedFile(event: any) {
        this.showDropzonePreview = true;
        this.entity.documentos[event.file.typeDocument].push(event.file);
        this.handleSaveDispatchReport(event.file, 1);
    }

    async onDeleteFileUploaded(event?: any, index?: number) {
        const documentosArray = this.entity.documentos[event.typeDocument];
        const result = await Swal.fire({
            title: 'Tem certeza que deseja anular este arquivo?',
            text: 'Esta ação não pode ser desfeita!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#417bff',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        });
        if (result.isConfirmed) {
            this.handleSaveDispatchReport(event, 0);

        } else {
            Swal.fire('Operação Cancelada', 'O arquivo não foi anulado.', 'info');
        }
    }

    handleSaveDispatchReport(file, status: number) {
        const data = {
            parecerDespacho: file,
            userId: this.global.user().USR_CodigoUsuario,
            status
        }

        this.skeletronLoaded.dispatch.items = status == 0? this.process.parecer_despacho.length : this.process.parecer_despacho.length + 1;
        this.skeletronLoaded.dispatch.show = true;
        this.processService.addDispatchReport(this.process.PRC_CodigoProcesso, data).subscribe({
            next: () => {
                this.getProcess(this.process.PRC_CodigoProcesso);

                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: status == 0? 'Parecer de despacho anulado com sucesso' : 'Um novo parecer de despacho foi adicionado ao processo.',
                });
            },
        });
    }

    public getUploadedComplementaryFiles(event: any) {
        this.showDropzonePreview = true;
        this.entity.processAttachments[event.file.typeDocument].push(event.file);
        const file = event.file;

        if (this.isCorrection) {
            this.complementaryFilesFromCorrection.push({
                PA_Nome: file.name,
                PA_CodigoProcesso: this.processId,
                PA_Status: 1,
                PA_Anexo: file.url,
                PA_CodigoUsuario: this.global.user().USR_CodigoUsuario,
            })
        }
        else {
            this.handleSaveComplementaryFiles(event.file, 1);
        }
    }

    public onCancelComplementaryFilesUploaded(event?: any, index?: number) {
        this.handleSaveComplementaryFiles(event, 0);
    }

    public handleSaveComplementaryFiles(file, status: number) {
        const data: IAddComplementaryFilesSchema = {
            complementaryFile: file,
            userId: this.global.user().USR_CodigoUsuario,
            status: status,
        }

        this.skeletronLoaded.complementaryFiles.items = status == 0? this.process.processo_anexo.length : this.process.processo_anexo.length + 1;
        this.skeletronLoaded.complementaryFiles.show = true;
        this.processService
            .addComplementaryFiles(this.process.PRC_CodigoProcesso, data)
            .subscribe({
                next: () => {
                    this.entity.despacho.PDES_Descricao = '';
                    this.getProcess(this.process.PRC_CodigoProcesso);
                    this.messageService.add({
                        severity: 'success',
                        icon: 'ph ph-check-circle',
                        closable: true,
                        summary: 'Sucesso!',
                        detail: status == 0?  'Documento complementar anulado com sucesso': 'Novo documento complementar adicionado ao processo',
                    });
                },
        });
    }

    public getUploadedCertificate(event: any) {        
        this.handleSaveCertificate(event?.file, 1);
    }

    public onDeleteCertificateUploaded(event?: any, index?: number) {
        this.handleSaveCertificate(event, 0);
    }

    public handleSaveCertificate(file: any, status: number) {
        const data = {
            certificates: file,
            userId: this.global.user().USR_CodigoUsuario,
            status
        }

        this.skeletronLoaded.certificate.items = status == 0? this.process.processo_certidao.length : this.process.processo_certidao.length + 1;
        this.skeletronLoaded.certificate.show = true;

        this.processService.addCertificate(this.process.PRC_CodigoProcesso, data).subscribe({
            next: () => {

                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: status == 0? 'Certidão anulada com sucesso' : 'Nova certidão adicionada ao processo',
                });

                this.getProcess(this.process.PRC_CodigoProcesso);
            },
        });
    }

    public getUploadedFixedAttachment(event: any) {
        this.showDropzonePreview = true;
        this.entity.fixedAttachments.documents.push(event.file);
    }

    public onDeleteFixedAttachmentUploaded(event?: any, index?: number) {
        const documentosArray = this.entity.fixedAttachments.documents;

        if (documentosArray && documentosArray.length >= event.index) {
            documentosArray.splice(event.index - 1, 1);
        }

        console.log('DOCUMENTOS', documentosArray);
    }

    getDocumentoTypes(documentos: any): string[] {
        return Object.keys(documentos);
    }

    handleSaveRequesterCorrection() {
        const verifyHaveAttachment = this.entity.fixedAttachments.documents.length === 0 && this.complementaryFilesFromCorrection.length === 0
        let message = '';

        if (!this.requesterConsiderations.trim()) {
            message += 'É necessário adicionar considerações para prosseguir.\n';
        }
    
        if (verifyHaveAttachment) {
            message += '\n É necessário adicionar um documento para prosseguir.\n';
        }
    
        if (!this.requesterConsiderations.trim() || verifyHaveAttachment) {
            Swal.fire({
                title: 'Ajustes',
                html: message.replace(/\n/g, '<br>'),
                icon: 'warning',
                confirmButtonColor: '#417bff',
                cancelButtonColor: '#d33',
            });
        } 
        else {
            Swal.fire({
                title: 'Prosseguir com a correção?',
                text: 'Antes de prosseguir, verifique se anexou corretamente os documentos corrigidos conforme as considerações do responsável.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#417bff',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    const correctionData = {
                        documents: this.entity.fixedAttachments.documents,
                        personId: this.global.user().PE_CodigoPessoa,
                        complementaryFiles: this.complementaryFilesFromCorrection
                    };

                    const considerationsData: ISendRequesterConsiderationsSchema = {
                        considerations: this.requesterConsiderations.trim(),
                        correctionId: this.process.PCOR_CodigoCorrecao,
                        PRCH_Historico: `O ${this.global.user().PE_Nome} adicionou considerações ao processo`
                    }
    
                    this.processService
                        .sendRequesterCorrection(this.process.PRC_CodigoProcesso, { correctionData, considerationsData})
                        .subscribe({
                            next: () => {
                                this.messageService.add({
                                    severity: 'success',
                                    icon: 'ph ph-check-circle',
                                    closable: true,
                                    summary: 'Sucesso!',
                                    detail: 'Sua correção será avaliada pelo responsável. Se estiver tudo certo, o responsável dará continuidade ao processo.',
                                    life: 8000
                                });
    
                                this.showDropzonePreview = false;
                                this.getProcess(this.process.PRC_CodigoProcesso);
                                this.getCorrections(this.process.PRC_CodigoProcesso);
                            },
                        });
                } else {
                    Swal.close();
                }
            });
        }
    }

    get hasFixedAttachmentsUploaded(): boolean {
        return (
            this.entity.fixedAttachments.procuracao.length > 0 ||
            this.entity.fixedAttachments.requerimento.length > 0 ||
            this.entity.fixedAttachments.complementar.length > 0
        );
    }

    public downloadFile(attachmentName: string, organization: string) {
        this.uploadService.download(attachmentName, organization).subscribe({
            next: (response: any) => {
                if (response.url) {
                    window.open(response.url, '_blank');
                }
            },
            error: (error: any) => {
                console.error('Erro no download:', error);
            },
        });
    }

    public navigateToDirectMessages() {
        this.global.router.navigate([`process/view/${this.encryptedId}/mail/received`]);
    }

    async searchResponsibles() {
        let condition = `"USR_UsuarioInterno" = true AND "PE_CodigoPessoa" != ${this.global.user().PE_CodigoPessoa}`

        if (this.userService.isDiretorDepartamento()) {
            condition = '"PE_CodigoDepartamento" = ' + this.global.user().PE_CodigoDepartamento
        }

        this.userService.listAll(condition, 0).subscribe({
            next: (response: any) => {
                this.responsibles = response.data;
            }
        })
    }

    public toggleFields() {
        if (this.tipoTramitacao === 'interna') {
            this.entity.tramitacao.PRC_CodigoDepartamentoAtual = null;
        } else if (this.tipoTramitacao === 'externa') {
            this.getDepartments();
            this.entity.tramitacao.PRC_CodigoResponsavel = null;
        } else if (this.tipoTramitacao === 'externa-orgao') {
            this.getOrganization();
            this.entity.tramitacao.PRC_CodigoResponsavel = null;
        }
    }

    public getOrganization() {
        this.organizations$ = this.organizationService.listAll(`"OR_TipoProcessoAceito" = ${typeProcessEnum.INTERNO_EXTERNO} OR "OR_TipoProcessoAceito" = ${typeProcessEnum.EXTERNO}`);
    }

    public changeOrganization() {
        this.entity.tramitacao.PIT_CodigoDepartamentoAtual = null;
        this.organizationService.getEntity(this.organizationId).subscribe({
            next: (response: any) => {
                this.organizationName = response.entity.orgao.OR_Descricao;
            }
        })
        this.departamentsByOrg$ = this.departmentService.listAll(`"DEP_CodigoOrgao" = ${this.organizationId} AND ("DEP_TipoProcessoAceito" = ${typeProcessEnum.INTERNO_EXTERNO} OR "DEP_TipoProcessoAceito" = ${typeProcessEnum.EXTERNO})`);
    }

    public changeDepartaments() {
        this.departmentService.getEntity(this.entity.tramitacao.PRC_CodigoDepartamentoAtual).subscribe({
            next: (response: any) => {
                this.departamentName = response.entity.DEP_Descricao
            }
        });
    }

    public async handleReturnToRequester() {
        this.sidebarVisible = false;

        const sendToCorrectionData = {
            description: this.reasonReturn,
            history: `O processo foi movido para correção por ${this.global.user().PE_Nome} `,
            attachments: this.entity.documentos.correctionFiles,
            editDescription: this.editDescriptionAllowed,
            personId: this.global.user().PE_CodigoPessoa
        };

        await this.processService.sendToCorrection(this.process.PRC_CodigoProcesso, sendToCorrectionData).subscribe({
            next: () => {
                this.reasonReturn = '';
                this.messageService.add({
                    severity: 'success',
                    icon: 'ph ph-check-circle',
                    closable: true,
                    summary: 'Sucesso!',
                    detail: 'O processo foi enviado para correção.',
                });

                this.getProcess(this.process.PRC_CodigoProcesso);
            },
        });
    }

    public correctNameType(type: string) {
        if (type == 'procuracao') {
            return 'PROCURAÇÃO';
        } else {
            return type;
        }
    }

    public deleteFile(name: string, table: string) {

        Swal.fire({
            title: 'Tem certeza que deseja apagar este arquivo?',
            text: 'Esta ação não pode ser desfeita!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#417bff',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.isConfirmed) {
                this.uploadService.deleteFile(name, table, this.process.OR_Sigla).subscribe({
                    next: () => {
                        this.getProcess(this.process.PRC_CodigoProcesso);
                        this.messageService.add({
                            severity: 'success',
                            icon: 'ph ph-check-circle',
                            closable: true,
                            summary: 'Sucesso!',
                            detail: 'O arquivo foi apagado com sucesso.',
                        });
                    }
                })
            } else {
                Swal.close()
            }
        });
    }

    getTiposDocumentos(): string[] {
        return Array.from(new Set(this.process.documentos.map((item) => item.SOLA_Tipo)));
    }

    getDocumentosByTipo(tipo: string): any[] {
        return this.process.documentos.filter((item) => item.SOLA_Tipo === tipo);
    }

    handleUpdateStatusToEvaluation() {
        Swal.fire({
            title: 'Tem certeza que deseja atualizar o processo para Em Análise?',
            text: 'Verifique se todos os documentos necessários estão corretos antes de realizar esta ação.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#417bff',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.isConfirmed) {
                this.processService.updateStatusToEvaluation(this.process.PRC_CodigoProcesso).subscribe({
                    next: () => {
                        this.getProcess(this.process.PRC_CodigoProcesso);
                        this.messageService.add({
                            severity: 'success',
                            icon: 'ph ph-check-circle',
                            closable: true,
                            summary: 'Sucesso!',
                            detail: 'O processo foi atualizado para Em Andamento',
                        });
                    }
                })
            } else {
                Swal.close()
            }
        });
    }

    filterDocumentByType(type: string) {
        const list = this.process.documentos.filter((document) => document.SOLA_Tipo === type);
        return list;
    }

    isNewDocumentType(type: string, index: number): boolean {
        if (!this.alreadyFilteredList[type]) {
            this.alreadyFilteredList[type] = index;
            return true;
        }
        return this.alreadyFilteredList[type] === index;
    }

    enableEditing() {
        this.isEditingDescription = true;
    }

    saveDescription() {
        const editDescriptionRequest: IChangeRequestDescriptionSchema = {
            requestId: Number(this.process.PRC_CodigoSolicitacao),
            description: this.process.SOL_Descricao
        }

        this.requestService.updateProcessDescription(editDescriptionRequest).subscribe({
            next: () => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Sucesso',
                    detail: 'Descrição atualizada com sucesso'
                });

                this.isEditingDescription = false;
            },
            error: (err) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: 'Falha ao atualizar a descrição'
                });
                console.error(err);
            }
        });
    }

    public get hasResponsible() {
        return this.process?.responsavel?.PE_CodigoPessoa == this.global.user()?.PE_CodigoPessoa
    }

    public get listResponsiblesRule() {
        if (this.responsibles) {
            return this.responsibles.filter((item) => item.PE_CodigoPessoa != this.global.user().PE_CodigoPessoa)
        }
    }

    public get InvalidNote(): boolean {
        return !this.entity.tramitacao.NTR_Descricao || !this.entity.tramitacao.NTR_Descricao.trim();
    }

    /* 
        Poderá corrigir os documentos caso:
        1 - For o solicitante
        2 - For criado pela triagem (Então qualquer um da triagem do órgão poderá fazer o upload dos novos documentos)
        3 - O processo esteja `Em Correção`
        4 - Ainda não for corrigido (PCOR_Status === 0)
    */
    public get canFixDocuments(): boolean {
        return (this.global.user().PE_CodigoPessoa === this.process.SOL_CodigoSolicitante ||
            (this.userService.isTriagem() && this.process.createdByGroup === 'TRIAGEM')) &&
            ProcessStatusEnum.IN_CORRECTION === this.process.Status &&
            this.process.PCOR_Status === 0
    }

    /* 
        Poderá adicionar considerações caso:
        1 - For o solicitante
        2 - For criado pela triagem (Então qualquer um da triagem do órgão poderá adicionar considerações)
        3 - O processo esteja `Em Correção`
        4 - Não exista um retorno do cidadão para a correção atual
    */
    public get canAddConsiderations(): boolean {
        return (this.global.user().PE_CodigoPessoa === this.process.SOL_CodigoSolicitante ||
            (this.userService.isTriagem() && this.process.createdByGroup === 'TRIAGEM')) &&
            (this.process.Status === ProcessStatusEnum.IN_CORRECTION) &&
            !this.process.PCOR_RetornoCidadao
    }

    /* 
        Poderá visualizar as informações de correção caso:
        1 - For o solicitante
        2 - O usuário logado seja do mesmo órgão em que o processo está vinculado
        3 - O processo esteja `Em Correção`;
    */
    public get canViewCorrectionDetails(): boolean {
        return (this.global.user().PE_CodigoPessoa === this.process.SOL_CodigoSolicitante ||
            this.global.user().USR_CodigoOrgao === this.process.SOL_CodigoOrgao) &&
            (this.process.Status === ProcessStatusEnum.IN_CORRECTION || this.process.Status === ProcessStatusEnum.ANSWERED)
    }

    /* 
        Poderá fazer upload dos documentos complementares caso:
        1 - For responsável pelo processo
        2 - For o solicitante
        3 - For criado pela triagem (Então qualquer um da triagem do órgão poderá fazer o upload)
        4 - É obrigatório está nos status de `Em Andamento` ou `Em Correção`;
    */
    public get canUploadProcessAttachments(): boolean {
        const allowedStatus = [ProcessStatusEnum.IN_PROGRESS, ProcessStatusEnum.IN_CORRECTION];

        return ((this.process.PRC_CodigoResponsavel == this.global.user().PE_CodigoPessoa) ||
            (this.global.user().PE_CodigoPessoa === this.process.SOL_CodigoSolicitante) ||
            (this.userService.isTriagem() && this.process.createdByGroup === 'TRIAGEM')) &&
            allowedStatus.includes(this.process.Status)
    }

    /* 
        Poderá visualizar e preencher os dados de anulação do processo caso:
        1 - Quem solicitou for do grupo `MEMBRO DE DEPARTAMENTO` e o usuário logado for do grupo `DIRETOR DE DEPARTAMENTO` 
        2 - O processo não estiver com o status `ANULADO`.
        3 - Ainda não foi avaliado.
    */

    public get isCancellationRequestSectionVisible(): boolean {
        return ((this.process.cancellation_requester_group === UsersGroupEnum.DEPARTMENT_MEMBER || this.userService.isSecretario() &&
            this.global.user().USG_CodigoGrupo === UsersGroupEnum.DEPARTMENT_DIRECTOR) ||
            this.global.user().USR_CodigoUsuario === this.process.PSA_Solicitante) &&
            this.process.Status !== ProcessStatusEnum.CANCELED &&
            !this.process.PSA_AvaliadaPor
    }

    /* 
        Poderá visualizar o botão de enviar solicitação de anulação caso:
        1 - O processo esteja nos status: `NOVO`, `EM ANDAMENTO`, `EM CORREÇÃO` ou `VENCIDO`;
        2 - Não tenha nenhuma solicitação de anulação pendente.
        3 - O responsável pelo processo seja o usuário logado.
        4 - O usuário logado seja um `MEMBRO DE DEPARTAMENTO`
    */
    public get isRequestCancellationButtonVisible(): boolean {
        return ((this.process.Status === ProcessStatusEnum.NEW ||
            this.process.Status === ProcessStatusEnum.IN_PROGRESS ||
            this.process.Status === ProcessStatusEnum.IN_CORRECTION ||
            this.process.Status === ProcessStatusEnum.OUTDATED) &&
            !this.process.PSA_DataSolicitacao) &&
            this.process.PRC_CodigoResponsavel === this.global.user().PE_CodigoPessoa &&
            this.userService.isMembroDepartamento()
    }

    /* 
        Poderá visualizar o botão de anular processo caso:
        1 - O processo esteja nos status: `NOVO`, `EM ANDAMENTO`, `EM CORREÇÃO` ou `VENCIDO`;
        2 - Não tenha nenhuma solicitação de anulação pendente.
        3 - O responsável pelo processo seja o usuário logado.
        4 - O usuário logado seja um `DIRETOR DE DEPARTAMENTO`
    */
    public get isCancellationButtonVisible(): boolean {
        return ((this.process.Status === ProcessStatusEnum.NEW ||
            this.process.Status === ProcessStatusEnum.IN_PROGRESS ||
            this.process.Status === ProcessStatusEnum.IN_CORRECTION ||
            this.process.Status === ProcessStatusEnum.OUTDATED) &&
            !this.process.PSA_DataSolicitacao) &&
            this.process.PRC_CodigoResponsavel === this.global.user().PE_CodigoPessoa &&
            (this.userService.isDiretorDepartamento() || this.userService.isAdmin())
    }

    public get canToggleResponsible(): boolean {
        return (this.userService.isDiretorDepartamento() && this.process.Status === ProcessStatusEnum.IN_CORRECTION)
    }

    public get visibleFilingNoteRule() {
        return this.process.nota_arquivamento && this.process.Status === ProcessStatusEnum.ARCHIVED
    }

    handleAddRequestToCancelProcess() {
        const cancelProcessData: IRequestToCancelProcessSchema = {
            reasonCancel: this.entity.cancelProcess.reasonCancel,
            userId: this.global.user().USR_CodigoUsuario,
            history: `${this.global.user().PE_Nome} enviou uma solicitação de anulação do processo.`
        }

        this.processService.addRequestToCancel(this.process.PRC_CodigoProcesso, cancelProcessData).subscribe({
            next: () => {
                this.getProcess(this.process.PRC_CodigoProcesso)

                this.isCancelProcessDialogOpen = false;

                this.messageService.add({
                    severity: 'success',
                    summary: 'Sucesso',
                    detail: 'Uma solicitação de anulação foi enviada para o diretor de seu departamento'
                });
            }
        })
    }

    handleCancelProcess() {
        const confirmProcessCancellationData: ICancellationEvaluationSchema = {
            userId: this.global.user().USR_CodigoUsuario,
            history: `${this.global.user().PE_Nome} confirmou a anulação do processo.`,
            evaluationDescription: this.entity.cancelProcess.reasonCancel
        }

        this.isCancelProcessDialogOpen = false;

        Swal.fire({
            title: 'Confirmar anulação do processo?',
            text: 'Após realizar esta ação, a anulação do processo não poderá ser desfeita.',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#417bff',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.isConfirmed) {
                this.processService.confirmCancellationProcess(this.process.PRC_CodigoProcesso, confirmProcessCancellationData).subscribe({
                    next: () => {
                        this.getProcess(this.process.PRC_CodigoProcesso)
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Sucesso',
                            detail: 'O processo foi anulado'
                        });
                    }
                })
            } else {
                this.isCancelProcessDialogOpen = true;
                Swal.close()
            }
        });
    }

    handleReturnToSender(): void {    
        if (!this.process || this.process.Status !== 2) {
            this.messageService.add({
                severity: 'warn',
                summary: 'Aviso',
                detail: 'Ação não permitida. Verifique se o processo está em andamento.',
            });
            return;
        }
    
        Swal.fire({
            title: 'Confirmar devolução ao transmitente?',
            text: 'Após esta ação, o processo será devolvido ao responsável anterior.',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#417bff',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.isConfirmed) {    
                this.processService.returnToSender(this.process.PRC_CodigoProcesso, {
                    userId: this.global.user().USR_CodigoUsuario,
                    history: `${this.global.user().PE_Nome} devolveu o processo ao transmitente anterior.`,
                }).subscribe({
                    next: () => {
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Sucesso',
                            detail: 'O processo foi devolvido ao transmitente anterior.',
                        });
                        this.getProcess(this.process.PRC_CodigoProcesso);
                        this.getHistories(this.process.PRC_CodigoProcesso);
                    },
                    error: (err) => {
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Erro',
                            detail: 'Falha ao devolver o processo. Tente novamente.',
                        });
                        console.error("[ERROR] Falha ao devolver o processo:", err);
                    },
                });
            }
        });
    }

    onProcessCancelled(processId: number) {
        this.getProcess(processId);
    }

    /* 
        O Arquivamento de processo pode ser feito se o processo:
        1 - Estiver Concluído
        2 - Estiver Vencido
        3 - Estiver Anulado
    */
    public get canArchive(): boolean {
        return (this.process.Status === ProcessStatusEnum.CONCLUDED ||
            this.process.Status === ProcessStatusEnum.OUTDATED ||
            this.process.Status === ProcessStatusEnum.CANCELED)
    }

    public showModalArchiveNote() {
        if (!this._haveDispatch && this.process.Status !== ProcessStatusEnum.OUTDATED && this.process.Status !== ProcessStatusEnum.CANCELED) {
            this.showMissingDispatchAlert('arquivacao');
        } else {
            this.isArchiveNoteModalOpen = true
        }
    }

    public get isArchivingInfoVisibile(): boolean {
        return (this.process.Status === ProcessStatusEnum.ARCHIVED &&
            !!this.process.PRC_NotaArquivamento
        )
    }

    public get requestUnarchivingButton() {
        return  ((this.userService.isDiretorDepartamento()) &&
        this.process.PRT_DepartamentoAtual === this.global.user().PE_CodigoDepartamento)
    }

    public get acceptUnarchivingRequestRule() {
        return (this.userService.isSecretario() && this.global.user().OR_CodigoOrgao == this.process.SOL_CodigoOrgao) 
               || this.userService.isAdmin()
    }

    getResponsibleRequests(processId: number) {
        this.processService.getResponsibleRequests(processId).subscribe({
            next: (response: any) => {
                this.responsibleRequest = response.data;
            }
        })
    }

    public get organizationIsSemurb() {
        return this.process.OR_NumeroOrgao == OrganizationEnum.SEMURB
    }

    public getCorrections(processId: number) {
        this.processService.getAllCorrections(processId).subscribe({
            next: (response: any) => {
                this.corrections = response.data;
            }
        })
    }

    acceptRejectBeingResponsible(type) {
        this.responsibleRequest.type = type
        let message: any;

        if (type == 'accept') {
            message = {
                severity: 'success',
                icon: 'ph ph-check-circle',
                closable: true,
                summary: 'Sucesso!',
                detail: 'Você aceitou ser responsável desse processo.'
            };
        }
        else {
            message = {
                severity: 'error',
                closable: true,
                detail: 'Você recusou ser responsável desse processo.'
            };
        }

        this.processService.sendAcceptBeingResponsible(this.responsibleRequest.RESAC_CodigoProcesso, this.responsibleRequest).subscribe({
            next: (response: any) => {
                this.getProcess(this.responsibleRequest.RESAC_CodigoProcesso);
                this.messageService.add(message);
                this.global.closeModalInfo('reject-responsible');
                window.location.reload();
            }
        })
    }

    getBucketNameFromTypeDocument(type: string, orgSigla: string) {
        switch (type) {
            case "procuracao":
                return null
            case "REQUERIMENTO":
                if (this.process.SOL_CodigoDocumento) return "SIGN";
                return orgSigla
            default:
                return orgSigla
        }    
    }

    public async handleCancelComplementaryFiles(fileId: number) {

        const result = await Swal.fire({
            title: 'Tem certeza que deseja anular este arquivo?',
            text: 'Esta ação não pode ser desfeita!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#417bff',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        });

        if (result.isConfirmed) {
            this.processService.cancelComplementaryFiles(this.process.PRC_CodigoProcesso, fileId).subscribe({
                next: (response: any) => {
                    Swal.fire('Arquivo Anulado!', 'Seu arquivo foi anulado com sucesso.', 'success');

                    this.getProcess(this.process.PRC_CodigoProcesso);
                    window.location.reload();
                },
                error: (error: any) => {
                    console.error('Erro ao anular o arquivo:', error);
                    Swal.fire('Erro ao anular o arquivo', 'Ocorreu um erro ao anular o arquivo.', 'error');
                }
            });
        } else {
            Swal.fire('Operação Cancelada', 'O arquivo não foi anulado.', 'info');
        }
    } catch(error) {
        console.error('Erro ao mostrar confirmação:', error);
    }

    async generatePDF(process) {
        this.isLoading = true;

        const listBlob = await this.processService.generateProcessPDF(process)

        const normalizedInternalProcessNumber = this.process.PRC_NumeroProcesso.replace(/\./g, ''); 
        
        const idDownload = this.globalStateService.addDownload({
            name: "Processo " + this.process.PRC_NumeroProcesso,
            type: 0,
            percent: 0
        })

        this.processService.mergedDocumentInPdf( 
            listBlob, 
            this.process.PRC_CodigoProcesso,
            this.process.PRC_NumeroProcesso.replace(/[.\-]/g, ''),
            this.process.OR_Sigla
        ).subscribe((event: HttpEvent<any>) => {
            this.globalStateService.getEventsRequest(event, idDownload);
            if (event.type == HttpEventType.Response) {
                if (event.body instanceof Blob) {
                    this.global.saveFile(event.body, `Processo - ${this.process.PRC_NumeroProcesso}.pdf`);
                    this.isLoading = false;
                }
            }
        });

    }

    get isCitizen() {
        return this.userService.isCidadao();
    }

    consultarDam(damId: string) {
        if (damId) {
          this.searchPerformed = true;
          this.global.startLoading('Consultando DAM...', true);
          this.fiscoService.getDataDam(damId).subscribe({
            next: (response: any) => {
              if (response.success) {

                if (this.process.dam) {
                    const filteredProcessDams = this.process.dam.filter(d => d.PDAM_NumeroDam === response.data.id.toString());
    
                    if (filteredProcessDams.length > 0) {
                        this.messageService.add({
                            severity: 'error',
                            icon: 'ph ph-x-circle',
                            closable: true,
                            summary: 'Erro!',
                            detail: 'O DAM que você está tentando adicionar já está no processo.'
                        });
                    } else {
                        this.damData = [response.data];
                        this.showDamTable = true;
                        this.isSearchDamModalOpen = true;
                    }
                } else {
                    this.damData = [response.data];
                    this.showDamTable = true;
                    this.isSearchDamModalOpen = true;
                }
                
              } else {
                this.damData = [];
                this.messageService.add({
                    severity: 'error',
                    icon: 'ph ph-x-circle',
                    closable: true,
                    summary: 'Erro!',
                    detail: 'Não foi possível encontrar o DAM que você buscou. Por favor, digite um número de DAM válido.'
                });
                this.showDamTable = false;
              }
            },
            error: (error) => {
              this.damData = [];
              this.showDamTable = false;
              this.global.stopLoading();
            },
            complete: () => {
                this.global.stopLoading();
            }
          });
        }
    }

    reopenProcess() {
        Swal.fire({
            title: 'Confirmar reabertura do processo?',
            text: 'Após realizar esta ação, a reabertura do processo não poderá ser interrompida.',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#417bff',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.isConfirmed) {
                this.global.startLoading();
                this.processService.reopenProcess(this.process.PRC_CodigoProcesso).subscribe({
                    next: () => {
                        this.getProcess(this.process.PRC_CodigoProcesso);
                        this.getHistories(this.process.PRC_CodigoProcesso);
                        this.global.stopLoading();
                    }
                })
            } else {
                Swal.close();
                this.global.stopLoading();
            }
        });
    }

    associarDamAoProcesso(damId: string) {
        this.isSearchDamModalOpen = false
        Swal.fire({
            title: 'Tem certeza que deseja associar este DAM ao processo?',
            text: 'Verifique se o DAM selecionado é o correto antes de confirmar.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#417bff',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.isConfirmed) {
                this.global.startLoading('Associando DAM...', true);
                this.processService.associarDam(this.process.PRC_CodigoProcesso, damId).subscribe({
                    next: () => {
                        this.messageService.add({
                            severity: 'success',
                            icon: 'ph ph-check-circle',
                            closable: true,
                            summary: 'Sucesso!',
                            detail: 'O DAM foi associado ao processo com sucesso.'
                        });
                        this.formattedDams.push(this.damData[0])
                        this.getProcess(this.process.PRC_CodigoProcesso);
                    },
                    error: (error) => {
                        this.messageService.add({
                            severity: 'error',
                            icon: 'ph ph-x-circle',
                            closable: true,
                            summary: 'Erro!',
                            detail: 'Ocorreu um erro ao associar o DAM ao processo.'
                        });
                        this.global.stopLoading();
                    },
                    complete: () => {
                        this.global.stopLoading();
                    }
                });
            } else {
                this.isSearchDamModalOpen = true;
                Swal.close();
            }
        });
    }

    removeDamFromProcess(damId: number) {
        Swal.fire({
            title: 'Tem certeza que deseja remover este DAM do processo?',
            text: 'Ao confirmar, o DAM será removido deste processo.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#417bff',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.isConfirmed) {
                this.processService.removeDam(this.process.PRC_CodigoProcesso, damId).subscribe({
                    next: () => {
                        this.messageService.add({
                            severity: 'success',
                            icon: 'ph ph-check-circle',
                            closable: true,
                            summary: 'Sucesso!',
                            detail: 'O DAM foi removido do processo.'
                        });
                        this.formattedDams = this.formattedDams.filter(dam => dam.id !== damId);
                        this.getProcess(this.process.PRC_CodigoProcesso);
                    },
                    error: (error) => {
                        this.messageService.add({
                            severity: 'error',
                            icon: 'ph ph-x-circle',
                            closable: true,
                            summary: 'Erro!',
                            detail: 'Ocorreu um erro ao remover o DAM do processo.'
                        });
                        this.global.stopLoading();
                    },
                    complete: () => {
                        this.global.stopLoading();
                    }
                });
            } else {
                Swal.close();
            }
        });
    }

    public get canChangeDam() {
        return (this.global.user().PE_CodigoPessoa === this.process.PRC_CodigoResponsavel) || this.userService.isAdmin()
    }

    get shouldShowReopenButton() {
        return this.userService.isAdmin() && this.validStatus.includes(this.process.Status);
    }

    skeletonHidden() {
        if (this.skeletronLoaded.complementaryFiles.show) {
            this.skeletronLoaded.complementaryFiles.show = false;
        }
        if (this.skeletronLoaded.dispatch.show) {
            this.skeletronLoaded.dispatch.show = false;
        }
        if (this.skeletronLoaded.certificate.show) {
            this.skeletronLoaded.certificate.show = false;
        }
    }
    
    get returnToSenderRole() {
        return (
            this.process.Status === ProcessStatusEnum.IN_PROGRESS && 
            this.process.lastResposible && 
            this.global.user().PE_CodigoPessoa === this.process.PRC_CodigoResponsavel &&
            this.process.lastResposible.HRA_CodigoResponsavel != this.global.user().PE_CodigoPessoa
        )
    }

    get isCorrection() {
        return this.process?.Status === ProcessStatusEnum.IN_CORRECTION
    }

    onDeleteComplementaryFilesUploaded(event) {
        const index =  (event.index - (this.process.processo_anexo.length - 1)) - 1;
        if (this.isCorrection) {
            this.complementaryFilesFromCorrection.splice(index, 1)
        }
    }
    
    get hasRequesterAndCorrection(): boolean {
        return this.process?.SOL_CodigoSolicitante == this.global.user().PE_CodigoPessoa && this.process?.Status == ProcessStatusEnum.IN_CORRECTION
    }

    get totalComplementaryFilesCount(): number {
        return this.process?.processo_anexo?.length || 0;
    }
      
    get hasNewComplementaryFiles(): boolean {
        if (!this.process?.processo_anexo) return false;
      
        const now = new Date().getTime();
        const twentyFourHoursAgo = now - 24 * 60 * 60 * 1000;
      
        return this.process.processo_anexo.some((file: any) => {
          const fileDate = new Date(file.created_at).getTime();
          return fileDate >= twentyFourHoursAgo;
        });
    }
}