import { Injectable } from '@angular/core';
import { GerinusService } from '../lib/gerinus.service';
import { GlobalService } from '../lib/global.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AreaSatisfactionService extends GerinusService {
  constructor(
    public http: HttpClient,
    public global: GlobalService,
  ) {
    super(http);
    this.entityName = 'processo_avaliacao';
    this.idField = 'PRA_CodigoAvaliacao';
    this.model = 'ProcessoAvaliacaoModel';
    this.orderBy = 'PRA_Data';
  }

  public getAverageRating(perMonth?: boolean) {
    const apiURL = `${this.apiURL()}/api/processo_avaliacao/average-rating`;
    return this.http.get<{ average: number }>(apiURL, {
      ...this.headers(),
      params: {perMonth}
    });
  }

  public getCount(perMonth?: boolean): Observable<{ total: number }> {
    const apiURL = `${this.apiURL()}/api/processo_avaliacao/count`;
    return this.http.get<{ total: number }>(apiURL, {
      ...this.headers(),
      params: {perMonth}
    });
  }

  public getRatingDistribution() {
    const apiURL = `${this.apiURL()}/api/processo_avaliacao/rating-distribution`;
    return this.http.get<{ ratingDistribution: { PRA_Avaliacao: number, count: number }[], maxCount: number }>(apiURL, this.headers());
  }

  public getTrend() {
    const apiURL = `${this.apiURL()}/api/processo_avaliacao/get-trend`;
    return this.http.get<number>(apiURL, this.headers());
  }
}

