import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GerinusBD } from '../lib/gerinus-bd.component';
import { GlobalService } from '../lib/global.service';

@Component({
    selector: 'gerinus-cad',
    templateUrl: './cad.component.html',
    styleUrls: ['./cad.component.scss'],
})
export class CadComponent implements OnInit {
    @Input() title: string;
    @Input() buttonLabel: string;
    @Input() owner: GerinusBD;
    @Input() icon: string;
    @Input() showFilter = true;
    @Input() customMarginTop: string = 'mt-4 mt-md-8 mt-lg-8';

    @Output() sendFilterItem = new EventEmitter<any>();

    public filterItem: any;
    public columnItem: any = '';
    public type: string = 'text';
    public isStatusSelected: boolean = false;
    public statuses: { value: number, name: string }[] = [
        { value: 1, name: 'Novo' },
        { value: 2, name: 'Em Andamento' },
        { value: 3, name: 'Arquivado' },
        { value: 4, name: 'Concluído' },
        { value: 5, name: 'Em Correção' },
        { value: 6, name: 'Vencido' }
    ];

    constructor(public global: GlobalService) {
    }

    ngOnInit() {}

    public sendFilter() {
        this.sendFilterItem.emit({
            name: this.filterItem,
            columName: !this.columnItem ? this.owner.filters[0].columName : JSON.parse(this.columnItem).columName,
        });
    }

    filter() {
        this.sendFilter();
        this.owner.afterCreate();
    }

    checkType(filter: any) {
        if (filter) {
            filter = JSON.parse(filter);
            if (filter.name === 'Status') {
                this.isStatusSelected = true;
                this.type = 'select';
            } else {
                this.isStatusSelected = false;
                this.type = filter.type || 'text';
            }
        }
    }
}