import { Component, OnInit } from '@angular/core';
import { FilterService, IAuditFilters } from 'src/app/app-core/services/filter.service';
import { GerinusBD } from 'src/app/app-core/lib/gerinus-bd.component';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { AuditService } from 'src/app/app-core/services/audit.service';
import { PrimeNGConfig } from 'primeng/api';
import { UserService } from 'src/app/app-core/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss'],
  providers: [AuditService, FilterService, UserService]
})
export class AuditComponent extends GerinusBD implements OnInit {
  public auditList: any[] = [];
  public customTitle: string = 'Consulta de Auditoria';

  //FILTROS
  public isFiltersModalOpen: boolean = false;
  public isFiltersModalResponsiveOpen: boolean = false;
  public createdAtRange: Date[] | undefined;
  public auditFilters: IAuditFilters;
  public owner: string;
  public action: string;
  public module: string;

  constructor(
    public global: GlobalService,
    public userService: UserService,
    private auditService: AuditService,
    private config: PrimeNGConfig,
    private activatedRoute: ActivatedRoute,
    private filterService: FilterService,
    private router: Router,
  ) {
    super(global, auditService);
    this.listAll();
  }

  ngOnInit() {
    this.config.setTranslation({
      apply: 'Aplicar',
      clear: 'Limpar',
      accept: 'Sim',
      reject: 'Não',
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
        'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje'
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.owner = params['owner'] || '';
      this.action = params['action'] || '';
      this.module = params['module'] || '';

      if (typeof params['createdAtRange'] === 'string' && params['createdAtRange'] !== '') {
        this.createdAtRange = params['createdAtRange'].split(',').map(date => new Date(date));
      }
      else {
        this.createdAtRange = [];
      }
    })
  }

  public listCondition() {
    let conditions = '';

    const additionalFilters = this.filter();

    if (typeof additionalFilters === 'string') {
      conditions = additionalFilters;
    }

    return conditions;
  }

  fetchAllProcessesFiltered() {
    this.listAll();

    this.isFiltersModalOpen = false;
    this.isFiltersModalResponsiveOpen = false;
  }

  filter() {
    this.auditFilters = {
      owner: this.owner,
      action: this.action,
      module: this.module,
      createdAtRange: this.createdAtRange ? this.createdAtRange.filter(date => date != null).map(date => date.toISOString()).join(',') : '',
    }

    const filterConditions = this.filterService.getAuditFilterConditions(this.auditFilters);

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.auditFilters,
      queryParamsHandling: 'merge',
    });

    return filterConditions;
  }

  clearFilters() {
    this.owner = null,
      this.action = null,
      this.module = null,
      this.createdAtRange = null,

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {},
      });

    this.listAll();

    this.isFiltersModalOpen = false;
    this.isFiltersModalResponsiveOpen = false;
  }

  get isClearFiltersButtonVisible(): boolean {
    return !!(
      this.action ||
      this.module ||
      (this.createdAtRange && this.createdAtRange.length > 0) ||
      this.owner
    );
  }
}