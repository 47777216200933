import { Injectable } from '@angular/core';
import { GlobalService } from '../lib/global.service';


export interface IRequestFilters {
    requestNumber: string;
    statuses: string;
    dateRange: string;
    requester: string;
    subject: string;
    organization: string;
}
export interface ICitizenFilters {
    owner: string;
    processCitizen: string;
    statusCitizen: string;
    statuses: string;
    dateRange: string;
}

export interface ICitizenStatuses {
    name: string;
    value: number;
}

export interface IUserFilters {
    name: string;
    group: string;
    organization: string;
    department: string;
}

export interface IOrganizationFilter {
    organizationName: string;
    organizationAbbreviation: string;
}

export interface IAtachmentsFilter {
    attachmentsName: string;
}

export interface IDepartmentsFilter {
    organization: string;
    department: string;
}

export interface ISubjectsFilter {
    subjectName: string;
    organization: string;
    department: string;
    status: boolean
}

export interface IProcessFilterStatuses {
    name: string;
    value: number;
}

export interface IProcessFilters {
    processNumber: string;
    requestNumber: string;
    statuses: string;
    createdAtRange: string;
    requester: string;
    subject: string;
    department: string;
    organization: string;
    owner: string;
    withoutOwner: boolean;
    responsibleAccept: boolean;
    my: boolean;
}

export interface IAuditFilters {
    owner: string;
    action: string;
    module: string;
    createdAtRange: string;
}
export interface IInternalProcessFilters {
    processNumber: string;
    statuses: string;
    createdAtRange: string;
    owner: string;
    withoutOwner: boolean;
    my: boolean;
}

export interface IInternalProcessFilterStatus {
    name: string;
    value: number;
}

export interface IAvaliationFilters {
    owner: string;
    avaliation: number;
    processAvaliation: number;
    createdAtRange: string[];
}

export interface IDashboardFilters {
    organization: string;
    department: string;
}

@Injectable()
export class FilterService {
    constructor(
        private global: GlobalService,
    ) {

    }

    public getRequestsFilterConditions(filters: any) {
        let conditions = [];

        if (filters.requestNumber) {
            conditions.push(`"SOL_NumeroSolicitacao" LIKE '%${filters.requestNumber}%'`);
        }

        if (filters.statuses) {
            conditions.push(`"SOL_Status" IN (${filters.statuses})`);
        }

        if (filters.dateRange) {
            const dateRangeArray = filters.dateRange.split(',');
            const formattedStartDate = new Date(dateRangeArray[0]).toISOString().split('T')[0];

            const formattedEndDate = dateRangeArray[1] ? new Date(dateRangeArray[1]).toISOString().split('T')[0] : formattedStartDate;

            if (formattedStartDate === formattedEndDate) {
                conditions.push(`DATE("SOL_DataSolicitacao") = '${formattedStartDate}'`);
            }
            else {
                conditions.push(`DATE("SOL_DataSolicitacao") BETWEEN '${formattedStartDate}' AND '${formattedEndDate}'`);
            }
        }

        if (filters.requester) {
            conditions.push(`(
                    remove_accents(LOWER("SOLR_NomeRazaoSocial")) LIKE remove_accents(LOWER('%${filters.requester}%')) 
                    OR remove_accents(LOWER("SOLR_CpfCnpj")) LIKE remove_accents(LOWER('%${filters.requester}%')) 
                    OR remove_accents(LOWER("PE_CPF")) LIKE remove_accents(LOWER('%${filters.requester}%')) 
                    OR remove_accents(LOWER("PE_Nome")) LIKE remove_accents(LOWER('%${filters.requester}%')) 
                )
            `);
        }

        if (filters.subject) {
            conditions.push(`"AS_Descricao" = '${filters.subject}'`);
        }

        if (filters.organization) {
            conditions.push(`"OR_Descricao" = '${filters.organization}'`);
        }

        return conditions.join(' AND ');
    }

    public getCitizenFilterConditions(filters: ICitizenFilters) {
        const conditions = [];

        if (filters.dateRange) {
            const dateRangeArray = filters.dateRange.split(',');
            const formattedStartDate = new Date(dateRangeArray[0]).toISOString().split('T')[0];

            const formattedEndDate = dateRangeArray[1] ? new Date(dateRangeArray[1]).toISOString().split('T')[0] : formattedStartDate;

            if (formattedStartDate === formattedEndDate) {
                conditions.push(`DATE("SOL_DataSolicitacao") = '${formattedStartDate}'`);
            }
            else {
                conditions.push(`DATE("SOL_DataSolicitacao") BETWEEN '${formattedStartDate}' AND '${formattedEndDate}'`);
            }
        }

    
        if (filters.owner) {
            conditions.push(`"SOL_NumeroSolicitacao" LIKE '%${filters.owner}%'`);
        }

    
        if (filters.processCitizen) {
            const fomattedProcessNumber = filters.processCitizen.replace(/(\d{4})(\d{2})(\d{2})(\d{4})/, '$1.$2.$3-$4');

            conditions.push(`"PRC_NumeroProcesso" = '${fomattedProcessNumber}'`);
        }

        if (filters.statusCitizen) {
            conditions.push(`"SOL_Status" IN (${filters.statusCitizen})`);
        }

        if (filters.statuses) {
            conditions.push(`"ProcessoStatus" IN (${filters.statuses})`);
        }
    
        return conditions.join(' AND ');

    }

    public getProcessFilterConditions(filters: IProcessFilters) {
        let conditions = [];

        if (filters.processNumber) {
            const fomattedProcessNumber = filters.processNumber.replace(/(\d{4})(\d{2})(\d{2})(\d{4})/, '$1.$2.$3-$4');

            conditions.push(`"PRC_NumeroProcesso" = '${fomattedProcessNumber}'`);
        }

        if (filters.requestNumber) {
            conditions.push(`"SOL_NumeroSolicitacao" = '${filters.requestNumber}'`);
        }

        if (filters.statuses) {
            conditions.push(`"Status" IN (${filters.statuses})`);
        }

        if (filters.createdAtRange) {
            const dateRangeArray = filters.createdAtRange.split(',');
            const formattedStartDate = new Date(dateRangeArray[0]).toISOString().split('T')[0];

            const formattedEndDate = dateRangeArray[1] ? new Date(dateRangeArray[1]).toISOString().split('T')[0] : formattedStartDate;

            if (formattedStartDate === formattedEndDate) {
                conditions.push(`DATE("PRC_DataRecebimento") = '${formattedStartDate}'`);
            }
            else {
                conditions.push(`DATE("PRC_DataRecebimento") BETWEEN '${formattedStartDate}' AND '${formattedEndDate}'`);
            }
        }

        if (filters.requester) {
            conditions.push(`
                remove_accents(LOWER("SOLR_NomeRazaoSocial")) LIKE remove_accents(LOWER('%${filters.requester}%')) 
                OR remove_accents(LOWER("nome_solicitante")) LIKE remove_accents(LOWER('%${filters.requester}%')) 
                OR remove_accents(LOWER("SOLR_CpfCnpj")) LIKE remove_accents(LOWER('%${filters.requester}%')) 
                OR remove_accents(LOWER("cpf_solicitante")) LIKE remove_accents(LOWER('%${filters.requester}%')) 
            `);
        }

        if (filters.subject) {
            conditions.push(`"AS_Descricao" = '${filters.subject}'`);
        }

        if (filters.department) {
            conditions.push(`"nomeDepartamentoAtual" = '${filters.department}'`);
        }

        if (filters.organization) {
            conditions.push(`"OR_Descricao" = '${filters.organization}'`);
        }

        if (filters.owner) {
            conditions.push(`
                remove_accents(LOWER("responsavel")) LIKE remove_accents(LOWER('%${filters.owner}%')) 
            `);
        }

        if (filters.withoutOwner && filters.withoutOwner === true) {
            conditions.push(`"PRC_CodigoResponsavel" IS NULL`);
        }

        if (filters.responsibleAccept === true) {
            conditions.push(`("RESAC_Status" = 0 AND "RESAC_CodigoResponsavel" = ${this.global.user().PE_CodigoPessoa})`);
        }

        if (filters.my && filters.my === true) {
            conditions.push(`"PRC_CodigoResponsavel" = ${this.global.user().PE_CodigoPessoa}`);
        }

        return conditions.join(' AND ');
    }

    public getInternalProcessFilterConditions(filters: IInternalProcessFilters) {
        let conditions = [];

        if (filters.processNumber) {
            const fomattedProcessNumber = filters.processNumber.replace(/(\d{4})(\d{2})(\d{2})(\d{4})/, '$1.$2.$3-$4');

            conditions.push(`"PI_NumeroProcessoInterno" = '${fomattedProcessNumber}'`);
        }

        if (filters.statuses) {
            conditions.push(`"PIT_Status" IN (${filters.statuses})`);
        }

        if (filters.createdAtRange) {
            const dateRangeArray = filters.createdAtRange.split(',');
            const formattedStartDate = new Date(dateRangeArray[0]).toISOString().split('T')[0];

            const formattedEndDate = dateRangeArray[1] ? new Date(dateRangeArray[1]).toISOString().split('T')[0] : formattedStartDate;

            if (formattedStartDate === formattedEndDate) {
                conditions.push(`DATE("PI_DataCriacao") = '${formattedStartDate}'`);
            }
            else {
                conditions.push(`DATE("PI_DataCriacao") BETWEEN '${formattedStartDate}' AND '${formattedEndDate}'`);
            }
        }

        if (filters.owner) {
            conditions.push(`remove_accents(LOWER("pessoaResponsavel"."PE_Nome")) LIKE remove_accents(LOWER('%${filters.owner}%'))`);
        }

        if (filters.withoutOwner && filters.withoutOwner === true) {
            conditions.push(`"PI_CodigoResponsavel" IS NULL`);
        }

        if (filters.my && filters.my === true) {
            conditions.push(`"PI_CodigoResponsavel" = ${this.global.user().PE_CodigoPessoa}`);
        }

        return conditions.join(' AND ');
    }

    public getUsersFilterConditions({ name, group, organization, department }: IUserFilters): string {
        let conditions = [];


        if (name) {
            conditions.push(`
                remove_accents(LOWER("PE_CPF")) LIKE remove_accents(LOWER('%${name}%')) 
                OR remove_accents(LOWER("PE_Nome")) LIKE remove_accents(LOWER('%${name}%'))
            `);
        }

        if (group) {
            conditions.push(`"GRP_Descricao" = '${group}'`)
        }

        if (organization) {
            conditions.push(`"OR_Descricao" = '${organization}'`)
        }

        if (department) {
            conditions.push(`"DEP_Descricao" = '${department}'`)
        }

        return conditions.join(' AND ') ?? ''
    }

    public getOrganizationFilterConditions({ organizationName, organizationAbbreviation }: IOrganizationFilter): string {
        let conditions = [];


        if (organizationName) {
            conditions.push(`
                remove_accents(LOWER("OR_Descricao")) LIKE remove_accents(LOWER('%${organizationName}%')) 
            `);
        }

        if (organizationAbbreviation) {
            conditions.push(`"OR_Sigla" = '${organizationAbbreviation}'`)
        }

        return conditions.join(' AND ') ?? ''
    }

    public getAttachmentFilterConditions({ attachmentsName }: IAtachmentsFilter): string {
        let conditions = [];

        if (attachmentsName) {
            conditions.push(`
                remove_accents(LOWER("ANX_Nome")) LIKE remove_accents(LOWER('%${attachmentsName}%')) 
            `);
        }

        return conditions.join(' AND ') ?? ''
    }

    public getDepartmentsFilterConditions({ organization, department }: IDepartmentsFilter): string {
        let conditions = [];

        if (organization) {
            conditions.push(`"OR_Descricao" = '${organization}'`)
        }

        if (department) {
            conditions.push(`"DEP_Descricao" = '${department}'`)
        }

        return conditions.join(' AND ') ?? ''
    }

    public getSubjectsFilterConditions({ subjectName, department, organization, status }: ISubjectsFilter): string {
        let conditions = [];

        if (subjectName) {
            conditions.push(`
                remove_accents(LOWER("AS_Descricao")) LIKE remove_accents(LOWER('%${subjectName}%')) 
            `);
        }

        if (department) {
            conditions.push(`"DEP_Descricao" = '${department}'`)
        }

        if (status) {
            conditions.push(`"AS_Status" = ${status}`)
        }

        if (organization) {
            conditions.push(`"OR_Descricao" LIKE '%${organization}%'`)
        }

        return conditions.join(' AND ') ?? ''
    }

    public getAuditFilterConditions(filters: IAuditFilters) {
        let conditions = [];

        if (filters.createdAtRange) {
            const dateRangeArray = filters.createdAtRange.split(',');
            const formattedStartDate = new Date(dateRangeArray[0]).toISOString().split('T')[0];

            const formattedEndDate = dateRangeArray[1] ? new Date(dateRangeArray[1]).toISOString().split('T')[0] : formattedStartDate;

            if (formattedStartDate === formattedEndDate) {
                conditions.push(`DATE("PRC_DataRecebimento") = '${formattedStartDate}'`);
            }
            else {
                conditions.push(`DATE("PRC_DataRecebimento") BETWEEN '${formattedStartDate}' AND '${formattedEndDate}'`);
            }
        }

        if (filters.owner) {
            conditions.push(`
                remove_accents(LOWER("PE_Nome")) LIKE remove_accents(LOWER('%${filters.owner}%')) 
            `);
        }

        if (filters.action) {
            conditions.push(`
                remove_accents(LOWER("AUD_Acao")) LIKE remove_accents(LOWER('%${filters.action}%')) 
            `);
        }

        if (filters.module) {
            conditions.push(`
                remove_accents(LOWER("AUD_Modulo")) LIKE remove_accents(LOWER('%${filters.module}%')) 
            `);
        }
    }

    public getAvaliationFilterConditions(filters: IAvaliationFilters) {
        const conditions = [];
    
        if (filters.createdAtRange) {
            const [startDate, endDate] = filters.createdAtRange;

            if (startDate && endDate) {
                conditions.push(`
                    DATE(processo_avaliacao.created_at) BETWEEN '${startDate}' AND '${endDate}'
                `);
            
            }
        }
    
        if (filters.owner) {
            conditions.push(`
                remove_accents("PE_Nome") ILIKE remove_accents('%${filters.owner}%')
            `);
        }

        if (filters.avaliation) {
            conditions.push(`
                "PRA_Avaliacao" = ${filters.avaliation}
            `);
        }
    
        if (filters.processAvaliation) {
            conditions.push(`
                remove_accents(LOWER("PRA_CodigoProcesso")) LIKE remove_accents(LOWER('%${filters.processAvaliation}%'))
            `);
        }
    
        return conditions.join(' AND ');
    }

    public getDashboardFilterConditions(filters: IDashboardFilters) {
        let conditions = [];

        if (filters.department) {
            conditions.push(`"DEP_Descricao" IN (${filters.department})`);
        }
        if (filters.organization) {
            conditions.push(`"OR_Descricao" IN (${filters.organization})`);
        }

        return conditions.join(' AND ');
    }
}
