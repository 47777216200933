import { Component, OnInit, Input } from '@angular/core';
import $ from 'jquery';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { LoginService } from 'src/app/app-core/services/login.service';
import { OrganizationTypeProccessEnum } from 'src/app/Enums/OrganizationTypeProccessEnum';

@Component({
    selector: 'app-menu',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
    providers: [LoginService, GlobalService],
})
export class MenuItemComponent implements OnInit {
    @Input() menu;

    ngOnInit(): void {
        $('.children-item').click(function () {
            $(this).find('.caret').toggleClass('rotate-180');
        });
    }

    constructor(
        public loginService: LoginService,
        public global: GlobalService
    ) { }

    getProcessRule(menu) {
        let access = true;

        if (this.typeProcess == OrganizationTypeProccessEnum.INTERNO && menu.name == 'Gestão de Processos') {
            access = false;
        }
        if (this.typeProcess == OrganizationTypeProccessEnum.EXTERNO && menu.name == 'Processos Internos') {
            access = false;
        }

        return access;
    }

    get typeProcess() {
        return this.global.user().OR_TipoProcessoAceito;
    }
}
