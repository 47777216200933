import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';
import { Observable } from 'rxjs';
import { IDocumentSignatureSave } from 'src/app/interfaces/document.interface';
import { IReponsibleSign } from 'src/app/interfaces/signature-gov.interfaces';

@Injectable()
export class DocumentService extends GerinusService {
    constructor(public http: HttpClient) {
        super(http);
        this.entityName = 'documento';
        this.idField = 'DOC_CodigoDocumento';
        this.model = 'DocumentoModel';
    }

    getVericityOfDocument(authKey: string) {
        return this.http.get(this.apiURL() + `/api/free/document/${authKey}`);
    }

    getDocumentType(routerType: string): string {
        switch (routerType) {
            case 'official-letter':
                return 'oficio';
            case 'memo':
                return 'memorando';
            case 'ordinances':
                return 'portaria';
            case 'certificates':
                return 'certidao';
            case 'normative-instructions':
                return 'instrucao-normativa';
            case 'declarations':
                return 'declaracao';
            case 'internal-comunications':
                return 'comunicacao-interna';
        }

        return '';
    }

    saveSignDocument(documentData: IDocumentSignatureSave): Observable<any> {
        return this.http.post(this.apiURL() + `/api/document/signature`, documentData, this.headers())
    }

    saveResponsibleSignature(documentData: IReponsibleSign): Observable<any> {
        return this.http.post(this.apiURL() + `/api/document/responsible-singature`, documentData, this.headers());
    }
}
