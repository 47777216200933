export enum internalProcessStatusText {
    // 1 => Novo, 2 => Em Andamento, 3 => Arquivado, 4 => Concluído'
    NOVO = 'Novo',
    EM_ANDAMENTO = 'Em Andamento',
    ARQUIVADO = 'Arquivado',
    CONCLUIDO = 'Concluído',
    TRAMITADO = 'Tramitado',
    CORRECAO = 'Correção',
    RESPONDIDO = 'Respondido',
    VENCIDO = 'Vencido',
    ANULADO = 'Anulado',
    EM_TRIAGEM = 'Em Triagem'
}

export enum internalProcessStatusEnum {
    // 1 => Novo, 2 => Em Andamento (3 => Arquivado, 4 => Concluído, => 5 TRAMITADO)'
    NOVO = 1,
    EM_ANDAMENTO = 2,
    ARQUIVADO = 3,
    CONCLUIDO = 4,
    TRAMITADO = 5,
    CORRECAO = 6,
    RESPONDIDO = 7,
    VENCIDO = 8,
    ANULADO = 9,
    EM_TRIAGEM = 10
}

export enum internalProcessCorrectionStatus {
    EM_CORRECAO = 0,
    CORRIGIDO = 1
}

export enum internalProcessGeneratesDocumentsName {
    INTERNAL_PROCESS = "internalProcess",
    DOCUMENT_OF_PROCESS = "documentosDoProcesso",
    DOCUMENT_CANCELED = "documentosCancelados"
}