import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { GerinusBD } from 'src/app/app-core/lib/gerinus-bd.component';
import { Dropdown } from 'primeng/dropdown';

import { ProcessService } from 'src/app/app-core/services/process.service';
import { RequestService } from 'src/app/app-core/services/request.service';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { ProccessHistoryService } from 'src/app/app-core/services/proccessHistorory.service';
import { CryptoService } from 'src/app/app-core/services/crypto.service';
import { UserService } from 'src/app/app-core/services/user.service';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { DepartmentService } from 'src/app/app-core/services/department.service';
import { SubjectService } from 'src/app/app-core/services/subject.service';
import { FilterService, IProcessFilters, IProcessFilterStatuses } from 'src/app/app-core/services/filter.service';

import { ISubject } from 'src/app/interfaces/subject';
import { IDepartment } from 'src/app/interfaces/department';
import { IListScopeDepartmentResponse } from 'src/app/interfaces/listScope/listScopeDepartmentResponse';
import { IListScopeSubjectResponse } from 'src/app/interfaces/listScope/listScopeSubjectResponse';
import { IOrganization } from 'src/app/interfaces/organization';
import { OrganizationService } from 'src/app/app-core/services/organization.service';
import { IListScopeOrganizationResponse } from 'src/app/interfaces/listScope/listScopeOrganizationResponse';
import { DatePipe } from '@angular/common';
import { SecondsToDaysPipe } from 'src/app/date-to-days-pipe.pipe';

@Component({
    selector: 'app-process',
    templateUrl: './process.component.html',
    styleUrls: ['./process.component.scss'],
    providers: [
        ProcessService,
        RequestService,
        ProccessHistoryService,
        UserService,
        MessageService,
        DepartmentService,
        SubjectService,
        OrganizationService,
        FilterService
    ],
})
export class ProcessComponent extends GerinusBD implements OnInit {

    @ViewChild('departmentDropdown') departmentDropdown: Dropdown;
    @ViewChild('subjectDropdown') subjectDropdown: Dropdown;
    @ViewChild('organizationDropdown') organizationDropdown: Dropdown;

    public isTableHistoryOpen: boolean[] = [];
    public history: any[] = [];
    public routerTypeParam: string = '';
    public customTitle: string = 'Consulta de Processos';

    //FILTROS
    public isFiltersModalOpen: boolean = false;
    public isFiltersModalResponsiveOpen: boolean = false;
    public processStatuses: IProcessFilterStatuses[];
    public selectedStatuses: IProcessFilterStatuses[];
    public processNumber: string;
    public requestNumber: string;
    public requester: string;
    public subjects: ISubject[];
    public selectedSubject: string;
    public owner: string;
    public processFilters: IProcessFilters;
    public createdAtRange: Date[] | undefined;
    public withoutOwner: boolean = false;
    public responsibleAccept: boolean = false;
    public my: boolean = false;
    public selectedDepartment: string;
    public departments: IDepartment[] | undefined;
    public selectedOrganization: string;
    public organizations: IOrganization[] | undefined;

    constructor(
        public global: GlobalService,
        public processService: ProcessService,
        public requestService: RequestService,
        public departmentService: DepartmentService,
        public organizationService: OrganizationService,
        public subjectService: SubjectService,
        public processHistoryService: ProccessHistoryService,
        private activatedRoute: ActivatedRoute,
        public cryptoService: CryptoService,
        public userService: UserService,
        private messageService: MessageService,
        private config: PrimeNGConfig,
        private router: Router,
        private filterService: FilterService,
    ) {
        super(global, processService);
    }

    ngOnInit() {
        this.afterCreate();

        this.config.setTranslation({
            apply: 'Aplicar',
            clear: 'Limpar',
            accept: 'Sim',
            reject: 'Não',
            firstDayOfWeek: 0,
            dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
            dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
            monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
                'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            today: 'Hoje'
        });

        this.processStatuses = [
            { name: 'Novo', value: 1 },
            { name: 'Em Andamento', value: 2 },
            { name: 'Arquivado', value: 3 },
            { name: 'Concluído', value: 4 },
            { name: 'Em Correção', value: 5 },
            { name: 'Vencido', value: 6 },
            { name: 'Anulado', value: 7 },
            { name: 'Respondido', value: 8 },
        ];

        this.activatedRoute.paramMap.subscribe(params => {
            this.routerTypeParam = params.get('type') || this.activatedRoute.snapshot.url[0]?.path;

            const typeToTitle = {
                sector: 'Processos no setor',
                transferred: 'Processos Tramitados',
                archived: 'Processos Arquivados',
                organization: 'Processos do órgão',
            };

            const defaultTitle = 'Processos';

            this.customTitle = typeToTitle[this.routerTypeParam] || defaultTitle;
            document.querySelector('title').text = this.customTitle;
        });

        this.activatedRoute.queryParams.subscribe(params => {

            this.requester = params['requester'] || '';
            this.processNumber = params['processNumber'] || '';
            this.requestNumber = params['requestNumber'] || '';
            this.selectedSubject = params['subject'] || '';
            this.owner = params['owner'] || '';
            this.withoutOwner = params['withoutOwner'] === 'true';
            this.responsibleAccept = params['responsibleAccept'] === 'true';
            this.my = params['my'] === 'true';
            this.selectedDepartment = params['department'] || '';
            this.selectedOrganization = params['organization'] || '';

            if (typeof params['statuses'] === 'string' && params['statuses'] !== '') {
                const statusArray = params['statuses'].includes(',') ? params['statuses'].split(',') : [params['statuses']];

                this.selectedStatuses = this.processStatuses && this.processStatuses.length > 0
                    ? statusArray.map(value => {
                        return this.processStatuses.find(status => status.value === +value);
                    }).filter(status => status !== undefined)
                    : [];
            } else {
                this.selectedStatuses = [];
            }

            if (typeof params['createdAtRange'] === 'string' && params['createdAtRange'] !== '') {
                this.createdAtRange = params['createdAtRange'].split(',').map(date => new Date(date));
            }
            else {
                this.createdAtRange = [];
            }
        });

        this.getDepartments();
        this.getOrganizations();
        this.getSubjects();
    }

    ngAfterViewInit(): void {
        this.activatedRoute.queryParams.subscribe(queryParams => {
            const notFound = queryParams['not-found'];
            if (notFound === 'true') {
                this.messageService.add({
                    severity: 'error',
                    icon: 'ph ph-x',
                    closable: true,
                    summary: 'Erro!',
                    life: 3000,
                    detail: 'O processo que você tentou acessar não existe!',
                });
            }
        });
    }

    handleCollapseRow(index: number) {
        this.isTableHistoryOpen[index] = !this.isTableHistoryOpen[index];
    }

    @HostListener('document:click', ['$event'])
    handleClick(event: Event) {
        const clickedInsideDepartmentDropdown = this.departmentDropdown?.containerViewChild?.nativeElement.contains(event.target);
        const clickedInsideSubjectDropdown = this.subjectDropdown?.containerViewChild?.nativeElement.contains(event.target);


        if (!clickedInsideDepartmentDropdown) {
            this.departmentDropdown?.hide();
        }

        if (!clickedInsideSubjectDropdown) {
            this.subjectDropdown?.hide();
        }
    }

    getHistorics(processoId: number, index: number) {
        if (this.isTableHistoryOpen[processoId]) {
            this.history.splice(index, 1);
        } else {
            this.processHistoryService.listAll(`"PRCH_CodigoProcesso" = ${processoId}`, 0).subscribe({
                next: (response: any) => {
                    this.history.push({
                        data: response.data,
                        index,
                    });
                },
            });
        }
    }

    historyFilter(index: number) {
        return this.history.filter((history) => {
            return history.index === index;
        });
    }

    public listCondition() {
        const user = this.global.user();
        const organizationId = user.USR_CodigoOrgao;
        const departmentId = user.PE_CodigoDepartamento;
        const triagemDepartamento = user.OR_TriagemDepartamento;
        let conditions = '';

        if (this.userService.isAdmin()) {
            if (this.routerTypeParam === 'all') {
                conditions = `"PRT_DataFim" IS NULL`;
            } else if (this.routerTypeParam === 'archived') {
                conditions = `("Status" = 3 and "PRT_DataFim" IS NULL)`;
            }
            else if (this.routerTypeParam === 'organization') {
                conditions = `"SOL_CodigoOrgao" = ${organizationId}`;
            }
            else if (this.routerTypeParam === 'sector') {
                conditions = `"PRT_DepartamentoAtual" = ${departmentId} and "Status" NOT IN (3) and "PRT_DataFim" IS NULL`;
            }
        }

        if (this.userService.isTriagem()) {
            if (triagemDepartamento) {
                if (this.routerTypeParam === 'all') {
                    conditions = `"AS_CodigoDepartamento" = ${user.DEP_CodigoDepartamento} and "PRT_DataFim" IS NULL`;
                }
            }
            else {
                if (this.routerTypeParam === 'all') {
                    conditions = `"SOL_CodigoOrgao" = ${organizationId} and "PRT_DataFim" IS NULL`;
                }
            }
        }

        if (this.userService.isDiretorDepartamento() || this.userService.isSecretario() || this.userService.isAdmOrgao() || this.userService.isMembroDepartamento()) {
            if (this.routerTypeParam === 'organization') {
                conditions = `"SOL_CodigoOrgao" = ${organizationId}`;
            }
            else if (this.routerTypeParam === 'sector') {
                conditions = `"PRT_DepartamentoAtual" = ${departmentId} and "Status" NOT IN (3) and "PRT_DataFim" IS NULL`;
            }
            else if (this.routerTypeParam === 'transferred') {
                conditions = `"PRT_CodigoDepartamentoTramitador" = ${departmentId} and "Status" IN (1, 2, 5)`;
            }
            else if (this.routerTypeParam === 'archived') {
                conditions = `("Status" = 3 and "PRT_DataFim" IS NULL) and "SOL_CodigoOrgao" = ${organizationId}`;
            }
        }

        if (this.userService.isCidadao()) {
            if (this.routerTypeParam === 'all') {
                conditions = `"PRT_DataFim" IS NULL and "SOL_CodigoSolicitante" = ${user.PE_CodigoPessoa}`;
            }
        }

        const additionalFilters = this.filter();

        if (additionalFilters) {

            if (conditions === '') {
                conditions = additionalFilters;
            }
            else {
                conditions += ` AND ${additionalFilters}`;
            }
        }

        return conditions;
    }

    navigateToViewProccess(id: number): void {
        this.global.router.navigate(['/process/view/' + this.cryptoService.encrypt(id.toString())]);
    }

    formatTime(date) {
        const dataRecebimento = new Date(date);
        return dataRecebimento.setHours(dataRecebimento.getHours() + 3);
    }

    getDepartments() {
        this.departmentService.listAll('', 0).subscribe({
            next: (response: IListScopeDepartmentResponse) => {

                this.departments = response.data;

                if (
                    this.userService.isAdmOrgao() ||
                    this.userService.isDiretorDepartamento() ||
                    this.userService.isSecretario() ||
                    this.userService.isMembroDepartamento()
                ) {
                    this.departments = this.departments.filter(department => department.DEP_CodigoOrgao === this.global.user().USR_CodigoOrgao)
                }
            }
        })
    }

    getSubjects() {
        this.subjectService.listAll(`"AS_Status" = ${true}`, 0).subscribe({
            next: (response: IListScopeSubjectResponse) => {
                this.subjects = response.data;

                if (
                    this.userService.isAdmOrgao() ||
                    this.userService.isDiretorDepartamento() ||
                    this.userService.isSecretario() ||
                    this.userService.isMembroDepartamento()
                ) {
                    this.subjects = this.subjects.filter(subject => subject.AS_CodigoOrgao === this.global.user().USR_CodigoOrgao)
                }
            }
        })
    }

    getOrganizations() {
        this.organizationService.listAll('', 0).subscribe({
            next: (response: IListScopeOrganizationResponse) => {
                this.organizations = response.data
            }
        })
    }

    filter() {
        this.processFilters = {
            requestNumber: this.requestNumber,
            statuses: this.selectedStatuses ? this.selectedStatuses.map(status => status.value).join(',') : '',
            createdAtRange: this.createdAtRange ? this.createdAtRange.filter(date => date != null).map(date => date.toISOString()).join(',') : '',
            requester: this.requester,
            subject: this.selectedSubject,
            department: this.selectedDepartment,
            organization: this.selectedOrganization,
            owner: this.owner,
            processNumber: this.processNumber,
            withoutOwner: this.withoutOwner,
            responsibleAccept: this.responsibleAccept,
            my: this.my
        }

        const filterConditions = this.filterService.getProcessFilterConditions(this.processFilters);

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: this.processFilters,
            queryParamsHandling: 'merge',
        });

        return filterConditions;
    }

    fetchAllProcessesFiltered() {
        this.listAll();

        this.isFiltersModalOpen = false;
        this.isFiltersModalResponsiveOpen = false;
    }

    clearFilters() {
        this.requestNumber = null,
            this.processNumber = null,
            this.owner = null,
            this.withoutOwner = null,
            this.responsibleAccept = null,
            this.selectedStatuses = null,
            this.createdAtRange = null,
            this.requester = null,
            this.selectedSubject = null,
            this.selectedDepartment = null,
            this.selectedOrganization = null,
            this.selectedStatuses = null

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {},
        });

        this.listAll();

        this.isFiltersModalOpen = false;
        this.isFiltersModalResponsiveOpen = false;
    }

    get isClearFiltersButtonVisible(): boolean {
        return !!(
            this.requestNumber ||
            this.processNumber ||
            this.requester ||
            this.selectedSubject ||
            this.selectedOrganization ||
            this.selectedDepartment ||
            (this.selectedStatuses && this.selectedStatuses.length > 0) ||
            (this.createdAtRange && this.createdAtRange.length > 0) ||
            this.owner ||
            this.withoutOwner ||
            this.responsibleAccept ||
            this.my
        );
    }

    public get reportButtonRule() {
        return this.userService.isAdmin() || this.userService.isAdmOrgao() || this.userService.isDiretorDepartamento() || this.userService.isSecretario();
    }

    generateServiceListPDF() {
        let title = this.customTitle;
        let condition = this.filterCondition();

        if (this.userService.isAdmOrgao() || this.userService.isSecretario()) {
            title += ` - ${this.global.user().OR_Descricao}`
            condition = `"SOL_CodigoOrgao" = ${this.global.user().USR_CodigoOrgao} AND ${this.filterCondition()}`
        }
        else if (this.userService.isDiretorDepartamento() || this.userService.isMembroDepartamento()) {
            title += ` - ${this.global.user().DEP_Descricao}`
            condition = `"PRT_DepartamentoAtual" = ${this.global.user().DEP_CodigoDepartamento} AND ${this.filterCondition()}`
        }

        const formatDateDayHoursMin = (seconds: any) => {
            return new SecondsToDaysPipe().transform(seconds);
        }

        const formatDate = (date:  any) => {
            return new DatePipe('pt-BR').transform(date, 'dd/MM/yyyy HH:mm:ss', 'UTC');
        }

        this.processService.generateListPDF(
            title.toUpperCase(),
            {
                titles: ['NUP', 'DATA DE RECEBIMENTO', 'ASSUNTO', 'DEPARTAMENTO', 'STATUS', 'RESPONSÁVEL','RECEBIDO'],
                columns: [
                    this.processService.columnObject('PRC_NumeroProcesso'), 
                    this.processService.columnObject('PRC_DataRecebimento', formatDate),
                    this.processService.columnObject('AS_Descricao'), 
                    this.processService.columnObject('nomeDepartamentoAtual'),
                    this.processService.columnObject('Status', this.processService.getStatusDescription),
                    this.processService.columnObject('responsavel'), 
                    this.processService.columnObject('time_in_current_status', formatDateDayHoursMin)
                ]
            },
            condition
        )

    }
}
