import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppCoreModule } from '../../app-core.module';
import { GerinusToolsModule } from '../../lib/gerinus-tools.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { BlockUIModule } from 'ng-block-ui';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { AuditComponent } from 'src/app/pages/audit/audit.component';

@NgModule({
    imports: [
        CommonModule,
        GerinusToolsModule,
        AppCoreModule,
        ComponentsModule,
        AutoCompleteModule,
        SidebarModule,
        ToastModule,
        DropdownModule,
        BlockUIModule,
        DialogModule,
        CalendarModule,
        MultiSelectModule,
        CheckboxModule,
        DropdownModule,
    ],
    declarations: [AuditComponent],
    exports: [GerinusToolsModule],
})
export class AuditModule { }
