import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { RequestStatusEnum } from 'src/app/Enums/RequestStatusEnum';
import { GerinusBD } from 'src/app/app-core/lib/gerinus-bd.component';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { CryptoService } from 'src/app/app-core/services/crypto.service';
import { FilterService, ICitizenFilters, ICitizenStatuses, IProcessFilterStatuses} from 'src/app/app-core/services/filter.service';
import { OrganizationService } from 'src/app/app-core/services/organization.service';
import { PersonService } from 'src/app/app-core/services/person.service';
import { ProcessService } from 'src/app/app-core/services/process.service';
import { RequestService } from 'src/app/app-core/services/request.service';
import { RequestHistoryService } from 'src/app/app-core/services/requestHistory.service';
import { SubjectService } from 'src/app/app-core/services/subject.service';
import { UserService } from 'src/app/app-core/services/user.service';
import { IRequestStatuses } from 'src/app/interfaces/request.interface';
import { IGetEntityProccessResponse } from 'src/app/interfaces/getEntity/getEtityProccessResponse';
import { format } from 'date-fns';
import { ProcessStatusEnum } from 'src/app/Enums/ProcessStatusEnum';
import { ProccessHistoryService } from 'src/app/app-core/services/proccessHistorory.service';

@Component({
  selector: 'app-citizen',
  templateUrl: './citizen.component.html',
  styleUrls: ['./citizen.component.scss'],
  providers: [
    RequestService,
    RequestHistoryService,
    ProccessHistoryService,
    UserService,
    PersonService,
    OrganizationService,
    ProcessService,
    MessageService,
    SubjectService,
    FilterService,
    GlobalService
],
})
export class CitizenComponent extends GerinusBD implements OnInit {
  @ViewChild('subjectDropdown') subjectDropdown: Dropdown;

  @Input() title: string;
  @Input() buttonLabel: string;
  @Input() owner: string;
  @Input() showFilter = true
  @Input() customMarginTop: string;


  public isTableHistoryOpen: boolean[] = [];
  public typeRequestParam: string;
  public historics: any[] = [];
  public history: any[] = [];
  public today: Date = new Date();
  public customCitizen: string;
  public customTitle: string = 'Sistema de Protocolo Eletrônico';
  public createdAtRange: Date[] | undefined;
  public button: string
  public citizenFilters: ICitizenFilters
  public processCitizen: string;
  public statusCitizen: ICitizenStatuses[];

  //FILTROS
  public requestStatuses: IRequestStatuses[];
  public citizenStatuses: ICitizenStatuses[];
  public selectedStatuses: IRequestStatuses[];
  public citizenProcessStatuses: IProcessFilterStatuses[];
  public processStatuses: IProcessFilterStatuses[];
  public requestNumber: string;
  public requester: string;
  public selectedSubject: string;
  public isFiltersModalOpen: boolean = false;
  public isFiltersModalResponsiveOpen: boolean = false;

  constructor(
      public requestService: RequestService,
      public global: GlobalService,
      private activatedRoute: ActivatedRoute,
      private historicService: RequestHistoryService,
      public processHistoryService: ProccessHistoryService,
      public userService: UserService,
      public personService: PersonService,
      private cryptoService: CryptoService,
      private processService: ProcessService,
      private messageService: MessageService,
      private config: PrimeNGConfig,
      private router: Router,
      private filterService: FilterService
  ) {
      super(global, requestService);
  }

ngOnInit() {
    this.config.setTranslation({
        apply: 'Aplicar',
        clear: 'Limpar',
        accept: 'Sim',
        reject: 'Não',
        firstDayOfWeek: 0,
        dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
            'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        today: 'Hoje'
    });


    this.activatedRoute.queryParams.subscribe(params => {
        this.owner = params['owner'] || '';
        this.processCitizen = params['processCitizen'] || '';
    
        if (typeof params['dateRange'] === 'string' && params['dateRange'] !== '') {
          this.createdAtRange = params['dateRange'].split(',').map(date => new Date(date));
        }

        else {
          this.createdAtRange = [];
        }

        if (typeof params['statusCitizen'] === 'string' && params['statusCitizen'] !== '') {
          const statusArray = params['statusCitizen'].includes(',') ? params['statusCitizen'].split(',') : [params['statusCitizen']];

          this.selectedStatuses = this.requestStatuses && this.requestStatuses.length > 0
              ? statusArray.map(value => {
                  return this.requestStatuses.find(status => status.value === +value);
              }).filter(status => status !== undefined)
              : [];
      } else {
          this.selectedStatuses = [];
      }

      if (typeof params['statuses'] === 'string' && params['statuses'] !== '') {
        const statusArray = params['statuses'].includes(',') ? params['statuses'].split(',') : [params['statuses']];

        this.citizenProcessStatuses = this.processStatuses && this.processStatuses.length > 0
            ? statusArray.map(value => {
                return this.processStatuses.find(status => status.value === +value);
            }).filter(status => status !== undefined)
            : [];
    } else {
        this.citizenProcessStatuses = [];
    }

        });
    
    this.listAll();

    this.requestStatuses = this.getStatusesByParam();

    this.customCitizen = this.userService.isCidadao()
      ? 'mt-4 mt-md-5 mt-lg-5'
      : 'mt-4 mt-md-8 mt-lg-8';

      this.processStatuses = [
        { name: 'Novo', value: 1 },
        { name: 'Em Andamento', value: 2 },
        { name: 'Arquivado', value: 3 },
        { name: 'Concluído', value: 4 },
        { name: 'Em Correção', value: 5 },
        { name: 'Vencido', value: 6 },
        { name: 'Anulado', value: 7 },
        { name: 'Respondido', value: 8 },
    ];

}

getStatusesByParam() {

  return [
      { name: 'Triagem', value: 0 },
      { name: 'Aceitas', value: 1 },
      { name: 'Em Correção', value: 2 },
      { name: 'Anuladas', value: 3 },
      { name: 'Vencidas', value: 5 }
  ];
}


public listCondition() {
    const user = this.global.user();
    const personId = user.PE_CodigoPessoa;

    let conditions = `"SOL_CodigoSolicitante" = ${personId}`;

    const additionalFilters = this.filter();

    if (typeof additionalFilters === 'string') {
      if (additionalFilters) {
        conditions += ` AND ${additionalFilters}`;
      }
    }

    return conditions;
  }

  fetchAllRequestsFiltered() {
    this.listAll();

    this.isFiltersModalOpen = false;
  }

  filter() {
    this.citizenFilters = {
         owner: this.owner,
         processCitizen: this.processCitizen,
         statusCitizen: this.selectedStatuses ? this.selectedStatuses.map(status => status.value).join(',') : '',
         statuses: this.citizenProcessStatuses ? this.citizenProcessStatuses.map(status => status.value).join(',') : '',
         dateRange: this.createdAtRange ? this.createdAtRange.filter(date => date != null).map(date => date.toISOString()).join(',') : '',
    }

    const filterConditions = this.filterService.getCitizenFilterConditions(this.citizenFilters);


    this.router.navigate([], {
         relativeTo: this.activatedRoute,
         queryParams: this.citizenFilters,
         queryParamsHandling: 'merge',
    });

    return filterConditions;
  }

  clearFilters() {
    this.owner = null,
    this.processCitizen = null,
    this.createdAtRange = null,
    this.selectedStatuses = null,
    this.citizenProcessStatuses = null,

    this.router.navigate([], {
         relativeTo: this.activatedRoute,
         queryParams: {},
    });

    this.listAll();

    this.isFiltersModalOpen = false;
  }

  get isClearFiltersButtonVisible(): boolean {
    return !!(
      this.processCitizen ||
     (this.createdAtRange && this.createdAtRange.length > 0) ||
      this.owner ||
      (this.selectedStatuses && this.selectedStatuses.length > 0) ||
      (this.citizenProcessStatuses && this.citizenProcessStatuses.length > 0)
    );
  }

  add() {
      this.global.router.navigate(['/requests/new']);
  }


  getHistoricsRequest(requestId: number, index: number) {
    if (this.isTableHistoryOpen[requestId]) {
        this.historics.splice(index, 1);
    } else {
        this.historicService.listAll(`"SOLH_CodigoSolicitacao" = ${requestId}`).subscribe({
            next: (response: any) => {
                this.historics.push({
                    data: response.data,
                    index,
                });
            },
        });
    }
  }

  getHistoryProcess(processoId: number, index: number) {
    if (this.isTableHistoryOpen[processoId]) {
        this.history.splice(index, 1);
    } else {
        this.processHistoryService.listAll(`"PRCH_CodigoProcesso" = ${processoId}`, 0).subscribe({
            next: (response: any) => {
                this.history.push({
                    data: response.data,
                    index,
                });
            },
        });
    }
  }


  historyFilter(index: number) {
    return this.history.filter((history) => {
        return history.index === index;
    });
  }

  historicsFilter(index: number) {
    return this.historics.filter((historico) => {
        return historico.index === index;
    });
  }

  getStatusClass(status: number): any {
    return {
      triagem: status === RequestStatusEnum.evaluation,
      aceita: status === RequestStatusEnum.accepted,
      correcao: status === RequestStatusEnum.correction,
      anulada: status === RequestStatusEnum.canceled,
      corrigida: status === RequestStatusEnum.corrected,
      vencida: status === RequestStatusEnum.outdated,
      assinatura: status === RequestStatusEnum.sign_pending,
    };
  }
  
  getStatusDescription(status: number): string {
    const statusDescriptions = {
      [RequestStatusEnum.evaluation]: 'Triagem',
      [RequestStatusEnum.accepted]: 'Aceita',
      [RequestStatusEnum.correction]: 'Correção',
      [RequestStatusEnum.canceled]: 'Anulada',
      [RequestStatusEnum.corrected]: 'Corrigida',
      [RequestStatusEnum.outdated]: 'Vencida',
      [RequestStatusEnum.sign_pending]: 'Assinatura Pendente'
    };
  
    return statusDescriptions[status];
  }
  
  getStatusTooltip(status: number): string {
    const statusTooltips = {
      [RequestStatusEnum.evaluation]:
        'A solicitação está com o Departamento de Triagem e será encaminhado ao setor responsável, caso aprovado.',
      [RequestStatusEnum.correction]:
        'A solicitação requer ajustes. Realize as correções necessárias para prosseguirmos com o atendimento.',
      [RequestStatusEnum.accepted]:
        'A solicitação foi aceita. Você será notificado sobre qualquer atualização.',
      [RequestStatusEnum.corrected]:
        'A solicitação foi corrigida. Clique em “Ver” para detalhes sobre a conclusão.',
      [RequestStatusEnum.canceled]: 
      'Sua solicitação foi anulada. Clique em ”Ver” para consultar o motivo e obter mais detalhes.',
      [RequestStatusEnum.outdated]: 
      'A solicitação está vencida.',
      [RequestStatusEnum.sign_pending]: 
      'A solicitação requer a assinatura de um responsável autorizado.'
    };
  
    return statusTooltips[status];
  }
  
  getProcessClass(status: number): any {
    return {
      Novo: status === ProcessStatusEnum.NEW,
      Andamento: status === ProcessStatusEnum.IN_PROGRESS,
      Arquivado: status === ProcessStatusEnum.ARCHIVED,
      Concluido: status === ProcessStatusEnum.CONCLUDED,
      Correcao: status === ProcessStatusEnum.IN_CORRECTION,
      Vencido: status === ProcessStatusEnum.OUTDATED,
      Anulado: status === ProcessStatusEnum.CANCELED,
      Respondido: status === ProcessStatusEnum.ANSWERED,
    };
  }
  
  getProcessDescription(status: number): string {
    const statusDescriptions = {
      [ProcessStatusEnum.NEW]: 'Novo',
      [ProcessStatusEnum.IN_PROGRESS]: 'Em Andamento',
      [ProcessStatusEnum.ARCHIVED]: 'Arquivado',
      [ProcessStatusEnum.CONCLUDED]: 'Concluído',
      [ProcessStatusEnum.IN_CORRECTION]: 'Em Correção',
      [ProcessStatusEnum.OUTDATED]: 'Vencido',
      [ProcessStatusEnum.CANCELED]: 'Anulado',
      [ProcessStatusEnum.ANSWERED]: 'Respondido',
    };
  
    return statusDescriptions[status] || 'Desconhecido';
  }
  
  getProcessTooltip(status: number): string {
    const statusTooltips = {
      [ProcessStatusEnum.NEW]: '',
      [ProcessStatusEnum.IN_PROGRESS]: 'O processo está em análise. Você será notificado sobre qualquer atualização.',
      [ProcessStatusEnum.ARCHIVED]: 'Seu processo foi finalizado e arquivado pelo departamento responsável.',
      [ProcessStatusEnum.CONCLUDED]: 'O processo foi finalizado. Clique em “Ver” para detalhes sobre a conclusão',
      [ProcessStatusEnum.IN_CORRECTION]: 'O processo requer ajustes. Realize as correções necessárias para prosseguirmos com o atendimento.',
      [ProcessStatusEnum.OUTDATED]: 'O processo foi vencido.',
      [ProcessStatusEnum.CANCELED]: 'Seu processo foi anulado. Clique em ”Ver” para consultar o motivo e obter mais detalhes.',
      [ProcessStatusEnum.ANSWERED]: 'O processo foi respondido pelo cidadão.',
    };
  
    return statusTooltips[status];
  }

  handleCollapseRowRequest(index: number) {
      this.isTableHistoryOpen[index] = !this.isTableHistoryOpen[index];
  }

  handleCollapseRowProcess(index: number) {
    this.isTableHistoryOpen[index] = !this.isTableHistoryOpen[index];
}

  public handleNavigate(requestStatus: number, requestId: number) {
      let typeRouter: string = 'view';

      if (!this.userService.isAdmin() && requestStatus === 2) {
          typeRouter = 'edit';
      }

      const encryptedRequestId = this.cryptoService.encrypt(String(requestId));

      this.global.router.navigate([`/requests/${typeRouter}/${encryptedRequestId}`]);
  }

  public handleNavigateToProcess(processId: number) {
      const encryptedId = this.cryptoService.encrypt(String(processId));
      this.global.router.navigate([`/process/view/${encryptedId}`]);
  }

  public get reportButtonRule() {
      return this.userService.isAdmin() || this.userService.isAdmOrgao() || this.userService.isDiretorDepartamento() || this.userService.isSecretario();
  }

  public getProcess(id: number) {
    this.processService.getEntity(id).subscribe({
      next:(response: IGetEntityProccessResponse) => {
        console.log('[getProcess]:', response);
        
      } 
    })
  }

  get isCitizen() {
    return this.userService.isCidadao();
}
}
