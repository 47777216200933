import { Injectable } from '@angular/core';
import { GerinusService } from '../lib/gerinus.service';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApplicantRequestService extends GerinusService {

  constructor(
      public http: HttpClient,
      private datePipe: DatePipe,
  ) {
      super(http);
      this.entityName = 'solicitacao_requerente';
      this.idField = 'SOLR_CodigoRequerente';
      this.model = 'SolicitacaoRequerenteModel';
  }
}
