import { Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'view-document-form',
  templateUrl: './view-document-form.component.html',
  styleUrls: ['./view-document-form.component.scss'],
  providers: []
})
export class ViewDocumentFormComponent implements OnInit, OnChanges {

  @Input() iSrc: string;
  @Input() iSrcCloudFlare: string;

  public iframeCarregado = false;
  public iframeSignature = false;
  public safeUrl: SafeResourceUrl
  public safeUrlCloudFlare: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
    // console.log("view-document.form.constructor safeUrl", this.safeUrl)
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.iSrcCloudFlare) {
      this.safeUrlCloudFlare = this.sanitizer.bypassSecurityTrustResourceUrl(this.iSrcCloudFlare);
    }
  }

  ngOnInit(): void {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.iSrc);
    this.safeUrlCloudFlare = this.sanitizer.bypassSecurityTrustResourceUrl(this.iSrcCloudFlare);
  }
  
  public onIframeCarregado() {
    this.iframeCarregado = true;
  }

  public onIframeSignature() {
    this.iframeSignature = true;
  }

}
