import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/app-core/services/user.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    date: Date = new Date();

    constructor(private userService: UserService) {}

    ngOnInit() {}

    get isCidadao(): boolean {
        return this.userService.isCidadao();
    }
}
