import { Component, Input, Output, EventEmitter } from '@angular/core';
import { GlobalService } from '../global.service';
import { GerinusService } from '../gerinus.service';

@Component({
    selector: 'gerinus-paginacao',
    templateUrl: 'paginacao.component.html',
    providers: [GerinusService, GlobalService],
})
export class PaginacaoComponent {
    @Input() pagination;
    @Output() changePage = new EventEmitter();
    
    public range = 2;
    public pagesAround = [];

    constructor(public global: GlobalService, public service: GerinusService) {}

    ngOnChanges() {
        this.generatePagesAround();
    }

    public doPageChange(page) {
        let max = this.pagination.last_page;
        if (page >= 1 && page <= max) {
            this.changePage.emit(page);
        }
    }

    public generatePagesAround() {
        const currentPage = this.pagination.current_page;
        const totalPages = this.pagination.last_page;

        const startPage = Math.max(2, currentPage - this.range);
        const endPage = Math.min(totalPages - 1, currentPage + this.range);

        this.pagesAround = [];
        for (let i = startPage; i <= endPage; i++) {
            this.pagesAround.push(i);
        }
    }
}
