export enum SubjectAcceptSignEnum {
    FERIAS="FÉRIAS",
    EXPEDICAO_ALVARA_FUNCIONAMENTO = "EXPEDIÇÃO DO ALVARÁ DE FUNCIONAMENTO",
    RENOVACAO_ALVARA_FUNCIONAMENTO = "RENOVAÇÃO DE ALVARÁ DE FUNCIONAMENTO",
    CONSULTA_LEGISLACAO_TRIBUTARIA = "CONSULTA A LEGISLAÇÃO TRIBUTÁRIA",
    COMPROVACAO_PAGAMENTO_DAM = "COMPROVAÇÃO DE PAGAMENTO DE DAM",
    SOLICITACAO_DAM_PAGAMENTO_MULTA_TCE = "SOLICITAÇÃO DE DAM PARA PAGAMENTO DE MULTA APLICADA PELO TCE",
    NEGOCIACAO_MULTA_TCE = "NEGOCIAÇÃO DE MULTA APLICADA PELO TCE",
    RESTITUICAO_IMPOSTO_RENDA = "RESTITUIÇÃO DE IMPOSTO DE RENDA",
    COMPENSACAO_ISS = "COMPENSAÇÃO DE ISS",
    ENCAMINHAMENTO_DOCUMENTACAO_SEFIN = "ENCAMINHAMENTO DE DOCUMENTAÇÃO DE INTERESSE DA SEFIN",
    ITBI_BOLETO_COMPLEMENTAR = "ITBI - BOLETO COMPLEMENTAR",
    SOLICITACAO_EMISSAO_NFE_WEB_SERVICE = "SOLICITAÇÃO DE EMISSÃO DE NFS-E VIA WEB SERVICE - SOFTWARE PRÓPRIO",
    OVERLAY = "OVERLAY",
    BAIXA_DEBITOS_DIVIDA_ATIVA = "BAIXA DE DÉBITOS INSCRITOS EM DÍVIDA ATIVA",
    RESTITUICAO_ISS = "RESTITUIÇÃO DE ISS",
    COMPROVACAO_ALIQUOTA_SIMPLES_NACIONAL = "COMPROVAÇÃO DE ALÍQUOTA DO SIMPLES NACIONAL"
}
