import { requestDetailingDataTypeEnum } from "src/app/Enums/requestDetailingDataTypeEnum";

const endereco = {
    uf: {
        type: requestDetailingDataTypeEnum.TEXT
    },
    localidade: {
      type: requestDetailingDataTypeEnum.TEXT
    },
    logradouro: {
      type: requestDetailingDataTypeEnum.TEXT
    },
    numero: {
      type: requestDetailingDataTypeEnum.TEXT
    },
    complemento: {
      type: requestDetailingDataTypeEnum.TEXT
    },
    bairro: {
      type: requestDetailingDataTypeEnum.TEXT
    },
    CEP: {
      type: requestDetailingDataTypeEnum.TEXT
    },
}

const requerente =  {
    rg: {
      type: requestDetailingDataTypeEnum.TEXT,
    },
    orgaoExpedidor: {
      type: requestDetailingDataTypeEnum.TEXT,
    },
    telefone: {
      type: requestDetailingDataTypeEnum.TEXT, 
    },
    dataNascimento: {
      type: requestDetailingDataTypeEnum.DATE,
    },
    cargoFuncao: {
      type: requestDetailingDataTypeEnum.TEXT,
    },
    classe: {
      type: requestDetailingDataTypeEnum.TEXT,
    },
    lotacao: {
      type: requestDetailingDataTypeEnum.TEXT,
    },
    unidadeExercicio: {
      type: requestDetailingDataTypeEnum.TEXT,
    },
    dataEfetivoExercicio: {
      type: requestDetailingDataTypeEnum.DATE,
    },
    autoridadeRequerida: {
      type: requestDetailingDataTypeEnum.TEXT,
      list: [
          "Secretário(a) de Infraestrutura",
          "Secretário(a) de Educação",
          "Secretário(a) Regional Pecém",
          "Prefeito Municipal",
          "Ouvidor(a)",
          "Secretário(a) de Saúde",
          "Controlador(a)",
          "Secretário(a) de Administração",
          "Secretário(a) de Governo",
          "Secretário(a) de Trab. e Des.Social",
          "Procurador(a) Geral",
          "Secretário(a) Geral",
          "Secretário(a) de Finanças",
          "Coordenador(a) do FMPS",
          "Outros"
      ]
    },
    periodoAquisitivo: {
      type: requestDetailingDataTypeEnum.TEXT,
    },
    ...endereco
}

export const formVacationType = {
    requerente
};

