import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as QRCode from 'qrcode';

import { GerinusService } from '../lib/gerinus.service';

import { interRegular } from '../../../assets/fonts/inter/Inter-Regular-normal';
import { interSemiBold } from '../../../assets/fonts/inter/Inter-SemiBold-normal';

import { ICancelRequestSchema, IChangeRequestDescriptionSchema, Request } from 'src/app/interfaces/request';
import { RequestStatusEnum } from 'src/app/Enums/RequestStatusEnum';

import { environment } from 'src/environments/environment';
import { IStepOption } from 'src/app/interfaces/stepOption';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface IStepsFormRequest {
    requestData: IStepOption,
    forms: IStepOption,
    attachment: IStepOption,
    summary: IStepOption
}

@Injectable()
export class RequestService extends GerinusService {

    constructor(
        public http: HttpClient,
        private datePipe: DatePipe,
    ) {
        super(http);
        this.entityName = 'solicitacao';
        this.idField = 'SOL_CodigoSolicitacao';
        this.model = 'SolicitacaoModel';
    }

    public generateRequestPDF(request: Request) {
        const doc = new jsPDF('p', 'mm', 'a4');

        const logoProtocolize = new Image();
        const urlPublicRequest = environment.projectURL + '/free/solicitacao/' + request.SOL_CodigoSolicitacao;
        let qrcode: string;

        QRCode.toDataURL(urlPublicRequest, (_, url) => {
            var base64Data = url.split(',')[1];
            qrcode = atob(base64Data);
        });

        doc.addImage(qrcode, 'PNG', 166, 220, 23, 22);

        logoProtocolize.src = '../../assets/img/brand/logo-protocolizesga.png';
        doc.addImage(logoProtocolize, 'png', 57, 5, 95, 18);

        doc.addFileToVFS('Inter-Bold.ttf', interSemiBold);
        doc.addFont('Inter-Bold.ttf', 'Inter-Bold', 'normal');
        doc.setFont('Inter-SemiBold');

        doc.addFileToVFS('Inter-Regular.ttf', interRegular);
        doc.addFont('Inter-Regular.ttf', 'Inter-Regular', 'normal');

        doc.setFont('Inter-SemiBold');
        doc.setFontSize(16);
        doc.setTextColor('#0A2156');
        doc.text((request.SOLR_TipoOrgaoFiscalizador ? 'Protocolo N° ' : 'Solicitação N° ') + request.SOL_NumeroSolicitacao + '/' + request.SOL_AnoSolicitacao, 103, 35, { align: 'center' });

        const requestDateFormatted = this.datePipe.transform(request.SOL_DataSolicitacao, 'dd/MM/yyyy');

        doc.setFont('Inter-Regular');
        doc.setFontSize(12);
        doc.setTextColor('#687489');
        doc.text('Data da solicitação: ' + requestDateFormatted, 103, 42, {
            align: 'center',
        });

        autoTable(doc, {
            body: [
                [
                    {
                        content: 'Solicitante:',
                        styles: {
                            halign: 'left',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 'wrap',
                            fontSize: 10,
                            textColor: '#0a2156',
                            cellPadding: { left: 4, top: 2, right: 4 },
                        },
                    },
                ],
                [
                    {
                        content: request.SOLR_NomeRazaoSocial
                            ? `${this.formatCpfCnpj(request.SOLR_CpfCnpj)} - ${request.SOLR_NomeRazaoSocial}`
                            : `${this.formatCpfCnpj(request.PE_CPF)} - ${request.PE_Nome}`,
                        styles: {
                            halign: 'left',
                            fontSize: 12,
                            textColor: '#687489',
                            cellPadding: {
                                left: 4,
                                top: 2,
                                right: 4,
                                bottom: 2,
                            },
                        },
                    },
                ],
            ],
            theme: 'plain',
            startY: 55,
            styles: {
                fillColor: '#f2f5f9',
            },
        });

        autoTable(doc, {
            body: [
                [
                    {
                        content: 'Órgão:',
                        styles: {
                            halign: 'left',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 'wrap',
                            fontSize: 10,
                            textColor: '#0a2156',
                            cellPadding: { left: 4, top: 2, right: 4 },
                        },
                    },
                    {
                        content: 'Assunto:',
                        styles: {
                            halign: 'left',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 'auto',
                            fontSize: 10,
                            textColor: '#0a2156',
                            cellPadding: { left: 4, top: 2, right: 4 },
                            lineWidth: { left: 1, top: 0, right: 0, bottom: 0 },
                            lineColor: '#ffffff',
                        },
                    },
                ],
                [
                    {
                        content: request.OR_Descricao,
                        styles: {
                            halign: 'left',
                            fontSize: 12,
                            textColor: '#687489',
                            cellPadding: {
                                left: 4,
                                top: 2,
                                right: 4,
                                bottom: 2,
                            },
                        },
                    },
                    {
                        content: request.AS_Descricao,
                        styles: {
                            halign: 'left',
                            fontSize: 12,
                            textColor: '#687489',
                            cellPadding: {
                                left: 4,
                                top: 2,
                                right: 4,
                                bottom: 2,
                            },
                            lineWidth: { left: 1, top: 0, right: 0, bottom: 0 },
                            lineColor: '#ffffff',
                        },
                    },
                ],
            ],
            theme: 'plain',
            startY: 71,
            styles: {
                fillColor: '#f2f5f9',
            },
        });

        if (request.SOLR_CpfCnpj) {
            autoTable(doc, {
                body: [
                    [
                        {
                            content: 'Observações:',
                            styles: {
                                halign: 'left',
                                valign: 'middle',
                                font: 'Inter-SemiBold',
                                cellWidth: 'wrap',
                                fontSize: 10,
                                textColor: '#0a2156',
                                cellPadding: { left: 4, top: 2, right: 4 },
                            },
                        },
                    ],
                    [
                        {
                            content: 'A Solicitação foi realizada por Terceiros.',
                            styles: {
                                halign: 'left',
                                fontSize: 12,
                                textColor: '#687489',
                                cellPadding: {
                                    left: 4,
                                    top: 2,
                                    right: 4,
                                    bottom: 2,
                                },
                            },
                        },
                    ],
                ],
                theme: 'plain',
                startY: 87,
                styles: {
                    fillColor: '#f2f5f9',
                },
            });
        }

        autoTable(doc, {
            body: [
                [
                    {
                        content: 'Descrição da solicitação:',
                        styles: {
                            halign: 'left',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 'wrap',
                            fontSize: 10,
                            textColor: '#0a2156',
                            cellPadding: { left: 4, top: 2, right: 4 },
                        },
                    },
                ],
                [
                    {
                        content: request.SOL_Descricao,
                        styles: {
                            halign: 'left',
                            fontSize: 12,
                            textColor: '#687489',
                            cellPadding: {
                                left: 4,
                                top: 2,
                                right: 4,
                                bottom: 2,
                            },
                        }
                    },
                ],
            ],
            theme: 'plain',
            startY: request.SOLR_CpfCnpj ? 100 : 90,
            styles: {
                fillColor: '#f2f5f9',
            },
        });

        autoTable(doc, {
            body: [
                [
                    {
                        content: 'Situação:',
                        styles: {
                            halign: 'center',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 30,
                            fontSize: 12,
                            cellPadding: {
                                bottom: 0,
                                top: 0,
                                right: 0,
                                left: 0,
                            },
                            textColor: '#0A2156',
                        },
                    },
                    {
                        content: this.getStatusDescription(request.SOL_Status),
                        styles: {
                            halign: 'left',
                            fontSize: 12,
                            cellPadding: 3,
                            textColor: '#687489',
                        },
                    },
                    {
                        content: '',
                        rowSpan: 2,
                        styles: {
                            halign: 'center',
                            valign: 'middle',
                            fontSize: 12,
                            cellPadding: 6,
                            textColor: '#687489',
                        },
                    },
                ],
                [
                    {
                        content: 'Chave de verificação:',
                        styles: {
                            halign: 'center',
                            valign: 'middle',
                            font: 'Inter-SemiBold',
                            cellWidth: 50,
                            fontSize: 12,
                            cellPadding: {
                                bottom: 0,
                                top: 0,
                                right: 0,
                                left: 0,
                            },
                            textColor: '#0A2156',
                        },
                    },
                    {
                        content: request.SOL_ChaveAutenticidade,
                        styles: {
                            halign: 'left',
                            fontSize: 12,
                            cellPadding: 3,
                            textColor: '#687489',
                        },
                    },
                ],
                [
                    {
                        content:
                            'Acompanhe sua solicitação acessando https://web.protocolize.pmsga.ce.gov.br/principal ou aponte a câmera do seu celular para o QR Code ao lado.',
                        colSpan: 3,
                        styles: {
                            halign: 'left',
                            fontSize: 12,
                            cellPadding: 3,
                            textColor: '#687489',
                        },
                    },
                ],
            ],
            theme: 'plain',
            startY: 220,
            styles: {
                lineWidth: 0.2,
                lineColor: '#687489',
                font: 'Inter-Regular',
            },
        });

        const brasaoSga = new Image();
        brasaoSga.src = '../../assets/img/brand/brasao-sga.png';
        doc.addImage(brasaoSga, 'png', 73, 270, 20, 18);

        const line = new Image();
        line.src = '../../assets/img/brand/line.png';
        doc.addImage(line, 'png', 100, 275, 0.2, 10);

        doc.setFont('Inter-Regular');
        doc.setFontSize(12);
        doc.setTextColor('#0A2156');
        doc.text(request.OR_Descricao, 107, 279, { maxWidth: 40 });

        if (request.SOLR_CpfCnpj) {
            doc.addPage();
            doc.addImage(logoProtocolize, 'PNG', 55, 5, 95, 18);
            doc.setFont('Inter-Bold');
            doc.setFontSize(10);
            doc.setTextColor('#000000');
            doc.text('Termo de responsabilidade para abertura de processo', 15, 25);

            doc.setFont('Inter-Regular');
            doc.setFontSize(9);
            doc.setTextColor('#000000');
            doc.text(
                `Eu, ${request.SOLR_NomeRazaoSocial}, portador do documento de CPF n° ${this.formatCpfCnpj(request.SOLR_CpfCnpj)}, venho por meio deste, solicitar a abertura de um processo administrativo referente a ${request.AS_Descricao}, com o número de solicitação ${request.SOL_NumeroSolicitacao}/${request.SOL_AnoSolicitacao}, junto à ${request.OR_Descricao}, através da equipe de triagem.`,
                15, 30, { maxWidth: 175, align: 'justify' }
            );
            
            doc.text('Declaro estar ciente e concordar com as seguintes condições:', 15, 47);

            const terms = [
                {
                  title: '1. Responsabilidade pelo Acompanhamento:  Comprometo-me  a  acompanhar  o  andamento do meu processo diretamente junto ao órgão responsável,  seja por meio de telefone ou e-mail, cujos contatos me foram informados no ato da abertura do processo.',
                  style: { align: 'justify', maxWidth: 178 },
                  space: 10
                },
                {
                  title: '2. Correções e Prazos: Caso o processo seja devolvido para correção de pendências ou ajustes, reconheço que terei o  prazo máximo de 10 (dez) dias corridos para realizar as devidas correções. Caso o prazo não seja cumprido, estou ciente de que o processo será indeferido, e será necessário abrir um novo processo para dar continuidade à solicitação. As correções só poderão ser realizadas no departamento de triagem do órgão em que o processo foi originalmente aberto.',
                  style: { align: 'justify', maxWidth: 178 },
                  space: 14
                },
                {
                  title: '3. Compromisso com  as  Informações: Declaro que todas  as  informações fornecidas no ato da abertura  do  processo  são verdadeiras e precisas, e assumo total responsabilidade por qualquer erro ou omissão.',
                  style: {  maxWidth: 180, align: 'justify' },
                  space: 1
                },
              ];

            let currentY = 55;
            const pageWidth = doc.internal.pageSize.getWidth();

            terms.forEach(term => {
                const [boldText, ...normalTextArray] = term.title.split(':');
                const normalText = normalTextArray.join(':').trim();
            
                doc.setFont('helvetica', 'bold');
                doc.text(`${boldText}: `, 15, currentY);
            
                const boldTextWidth = doc.getTextWidth(`${boldText}: `);
                doc.setFont('helvetica', 'normal');
            
                const maxWidth = pageWidth - 30; 
                const lineSpacing = 4;          
            
                const firstLine = doc.splitTextToSize(normalText, maxWidth - boldTextWidth)[0];
                doc.text(firstLine, 15 + boldTextWidth, currentY, { align: 'justify' });
            
                currentY += lineSpacing;
            
                const remainingText = normalText.substring(firstLine.length).trim();

                doc.text(remainingText, 15, currentY, { align: 'justify', maxWidth: term.style.maxWidth });
                currentY += term.space;
            });

            currentY += 4;
            doc.text(
                'Ao assinar este termo, estou ciente e de acordo com todas as condições descritas, e comprometo-me a cumprir com minhas responsabilidades.',
                15, currentY, { maxWidth: 175, align: 'justify' }
            );

            currentY += 10;
            const finalText = 'São Gonçalo do Amarante - CE, ' + new Date().toLocaleDateString() + '.';
            const textWidth = doc.getTextWidth(finalText);
            doc.text(finalText, pageWidth - textWidth - 14, currentY);
            doc.text('_____________________________________________________________', pageWidth / 2, currentY + 10, { align: 'center' });
            doc.text('Assinatura do Responsável', pageWidth / 2, currentY + 15, { align: 'center' });

            currentY += 20;

            const logoSga = new Image();
            logoSga.src = '../../assets/img/brand/logo_sga_horizontal.png';
            doc.addImage(logoSga, 'png', 73, currentY, 30, 15);
            
            doc.addImage(line, 'png', 105, currentY + 2, 0.2, 10);

            doc.setFont('Inter-Regular');
            doc.setFontSize(12);
            doc.setTextColor('#0A2156');
            doc.text(request.OR_Descricao, 108, currentY + 6, { maxWidth: 40 });

            currentY += 20;
            doc.setLineDashPattern([2, 1], 0);
            doc.line(5, currentY, pageWidth - 4, currentY);

            currentY += 2;

            doc.addImage(logoProtocolize, 'PNG', 57, currentY, 95, 18);

            currentY += 20;
            doc.setFont('Inter-Bold');
            doc.setFontSize(10);
            doc.setTextColor('#000000');
            doc.text('Termo de responsabilidade para abertura de processo', 15, currentY);

            currentY += 1;
            doc.setFont('Inter-Regular');
            doc.setFontSize(9);
            doc.setTextColor('#000000');
            doc.text(
                `Eu, ${request.SOLR_NomeRazaoSocial}, portador do documento de CPF n° ${this.formatCpfCnpj(request.SOLR_CpfCnpj)}, venho por meio deste, solicitar a abertura de um processo administrativo referente a ${request.AS_Descricao}, com o número de solicitação ${request.SOL_NumeroSolicitacao}/${request.SOL_AnoSolicitacao}, junto à Secretaria Municipal de Finanças, através da equipe de triagem.`,
                15, currentY + 4, { maxWidth: 175, align: 'justify' }
            );

            currentY += 20;
            doc.text('Declaro estar ciente e concordar com as seguintes condições:', 15, currentY);

            currentY += 10;
            terms.forEach(term => {
                const [boldText, ...normalTextArray] = term.title.split(':');
                const normalText = normalTextArray.join(':').trim();
            
                doc.setFont('helvetica', 'bold');
                doc.text(`${boldText}: `, 15, currentY);
            
                const boldTextWidth = doc.getTextWidth(`${boldText}: `);
                doc.setFont('helvetica', 'normal');
            
                const maxWidth = pageWidth - 30; 
                const lineSpacing = 4;          
            
                const firstLine = doc.splitTextToSize(normalText, maxWidth - boldTextWidth)[0];
                doc.text(firstLine, 15 + boldTextWidth, currentY, { align: 'justify' });
            
                currentY += lineSpacing;
            
                const remainingText = normalText.substring(firstLine.length).trim();

                doc.text(remainingText, 15, currentY, { align: 'justify', maxWidth: term.style.maxWidth });
                currentY += term.space;
            });

            currentY += 6;
            doc.text(
                'Ao assinar este termo, estou ciente e de acordo com todas as condições descritas, e comprometo-me a cumprir com minhas responsabilidades.',
                15, currentY, { maxWidth: 175, align: 'justify' }
            );

            currentY += 15;
            doc.text(finalText, pageWidth - textWidth - 20, currentY);
            doc.text('_____________________________________________________________', pageWidth / 2, currentY + 10, { align: 'center' });
            doc.text('Assinatura do Responsável', pageWidth / 2, currentY + 15, { align: 'center' });

            currentY += 20;

            logoSga.src = '../../assets/img/brand/logo_sga_horizontal.png';
            doc.addImage(logoSga, 'png', 73, currentY, 30, 15);

            currentY += 20;

            doc.addImage(line, 'png', 105, 279, 0.2, 10);

            doc.setFont('Inter-Regular');
            doc.setFontSize(12);
            doc.setTextColor('#0A2156');
            doc.text(request.OR_Descricao, 108, 283, { maxWidth: 40 });
        }

        return doc.save(`Comprovante de Solicitação - N° ${request.SOL_CodigoSolicitacao}`);
    }

    getStatusDescription(requestStatus: number) {
        const statusDescriptions = {
            0: 'Em Processo de Triagem',
            1: 'Solicitação Aceita',
            2: 'Correção de Pendências',
            3: 'Anulada',
            4: 'Corrigida'
        };

        return statusDescriptions[requestStatus];
    }

    public isInEvaluation(status: number): boolean {
        return RequestStatusEnum.evaluation == status;
    }

    public isInCorrection(status: number): boolean {
        return RequestStatusEnum.correction == status;
    }

    public isAccepted(status: number): boolean {
        return RequestStatusEnum.accepted == status;
    }

    public viewPublicRequest(id: number) {
        return this.http.get(this.apiURL() + '/api/free/solicitacao/' + id);
    }

    public cancelRequest(data: ICancelRequestSchema): Observable<any> {
        return this.http.post(this.apiURL() + '/api/requests/cancel-request', data, this.headers())
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: any): Observable<never> {
        console.error('An error occurred:', error);
        return throwError(() => new Error('Something went wrong; please try again later.'));
    }

    public updateProcessDescription(data: IChangeRequestDescriptionSchema): Observable<any> {
        return this.http.post(this.apiURL() + '/api/requests/edit-description', data, this.headers())
            .pipe(
                catchError(this.handleError)
            );
    }

    public saveDocumentRequest(data) {
        return this.http.post(this.apiURL() + '/api/requests/documents', data, this.headers())
        .pipe(
            catchError(this.handleError)
        );
    }

    public signDocumentRequest(code: string, base64: string) {
        const data = {
            code,
            base64
        }
        return this.http.post(this.apiURL() + '/api/requests/documents-enveloped', data, this.headers())
        .pipe(
            catchError(this.handleError)
        );
    }

    countRequest(condition: string): Observable<number> {
        return this.http.get<number>(`${this.apiURL()}/api/requests/list-count?condition=${encodeURIComponent(condition)}`, this.headers());
    }
    
    public getScreeningProcess() {
        return this.http.get(this.apiURL() + '/api/requests/get-screening-process/', this.headers());
    }
}
