import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, QueryList, ViewChildren, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf'; 
import { PDFDocumentProxy } from 'pdfjs-dist/legacy/build/pdf';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DocumentFormService } from 'src/app/app-core/services/document-form.service';
import { ISignaturePlaceholder } from 'src/app/interfaces/signature-gov.interfaces';
import { environment } from 'src/environments/environment';

pdfjsLib.GlobalWorkerOptions.workerSrc = environment.pdfWorker;

@Component({
  selector: 'placeholder-sign',
  templateUrl: './placeholder-sign.component.html',
  styleUrls: ['./placeholder-sign.component.scss'],
  providers: [DocumentFormService]
})
export class PlaceholderSignComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild('container', { static: false }) container: ElementRef;
  @ViewChild('draggable', { static: false }) draggable: ElementRef;
  @ViewChildren('pdfCanvas') pdfCanvases: QueryList<ElementRef>;

  @Input() pdfBuffer: ArrayBuffer;
  @Input() base64DocumentSign: string;
  @Input() wasSign = false;
  @Input() isLoadingSign = false;

  @Output() signaturePosition = new EventEmitter<ISignaturePlaceholder>();  

  public pdfPages: any[] = []; 
  public pdfPagesNum = [];
  public pdfUrl: SafeResourceUrl;
  public position = { x: 50, y: 50 }; 
  public scaleFactor = 1.4; 
  private isDragging = false;
  private dragOffset = { x: 0, y: 0 };
  private resizeSubscription: Subscription;
  
  constructor(
    private documentService: DocumentFormService,
  ) { 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.base64DocumentSign) {
      this.pdfBuffer = this.convertBase64ToArrayBuffer(this.base64DocumentSign);
      this.generateDocumentAndRenderPages();
    }
  }

  ngOnInit(): void {
    this.generateDocumentAndRenderPages();
    this.emitSignatureOutOfScopeEvent();
  }

  ngAfterViewInit() {
    this.resizeSubscription = fromEvent(window, 'resize')
                                      .pipe(debounceTime(200)) 
                                      .subscribe(() => {
                                        this.adjustScale(); 
                                        // this.adjustDraggablePlaceholder();
                                      });

    this.setInitialPosition();
  }


  adjustScale() {
    const containerWidth = this.container.nativeElement?.offsetWidth!;

    if (!this.pdfBuffer) {
      return;
    }

    const clonedBuffer = new Uint8Array(this.pdfBuffer.slice(0));
    pdfjsLib.getDocument(clonedBuffer).promise.then((pdf) => {
      pdf.getPage(1).then((page) => {
        const originalWidth = page.view[2]; 


        this.scaleFactor = (containerWidth * 0.6) / originalWidth;

        this.generateDocumentAndRenderPages();
      });
    });
  }

  setInitialPosition(): void {
    const firstCanvas = this.pdfCanvases.first?.nativeElement;
  
    if (firstCanvas) {
      const rect = firstCanvas.getBoundingClientRect();
      this.position = {
        x: 50, 
        y: 50,
      };

    }
  }


  adjustDraggablePlaceholder(): void {
    const firstCanvas = this.pdfCanvases.first?.nativeElement;
  
    if (!firstCanvas) {
      return;
    }
  
    const rect = firstCanvas.getBoundingClientRect();
  
    const scale = this.scaleFactor;
  
    const placeholderWidth = 200 * scale; 
    const placeholderHeight = 80 * scale; 
  
    const placeholder = this.draggable.nativeElement;
    if (placeholder) {
      placeholder.style.width = `${placeholderWidth}px`;
      placeholder.style.height = `${placeholderHeight}px`;
  
      const newPositionX = rect.left + (this.position.x * scale);
      const newPositionY = rect.top + (this.position.y * scale);
  
      placeholder.style.left = `${newPositionX}px`;
      placeholder.style.top = `${newPositionY}px`;
    }
  }

  async generateDocumentAndRenderPages() {
    const clonedBuffer = new Uint8Array(this.pdfBuffer.slice(0)); 
    const pdf = await pdfjsLib.getDocument(clonedBuffer).promise;
    
    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
      this.pdfPagesNum.push(true);
      await this.renderPage(pageNum, pdf);
    }

  }

  async renderPage(pageNum: number, pdf?: PDFDocumentProxy) {
    try {
      const page = await pdf.getPage(pageNum);
      
      const canvas = this.pdfCanvases.toArray()[pageNum - 1]?.nativeElement;
      const context = canvas?.getContext('2d');
      
      if (!canvas || !context) {
        return;
      }
      
      const viewport = page.getViewport({ scale: this.scaleFactor});
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      await page.render({
        canvasContext: context,
        viewport: viewport,
      }).promise;
      
      console.log(`Página ${pageNum} renderizada com sucesso.`);
    } catch (err) {
      console.error("Erro ao renderizar a página: ", err);
    }
  }

  handleClick(event: MouseEvent, pageNum: number): void {
    const canvas = this.pdfCanvases.toArray()[pageNum]?.nativeElement;
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left; 
    const y = event.clientY - rect.top;  

    const xTotalPercentage = (x / rect.width) * 100;
    const yTotalPercentage = (y / rect.height) * 100;
  
    const width = canvas.width;
    const height = canvas.height;
  }

  startDragging(event: MouseEvent) {
    this.isDragging = true;
    this.dragOffset = {
      x: event.clientX - this.position.x,
      y: event.clientY - this.position.y,
    };
  }

  onMouseMove(event: MouseEvent) {
    if (this.isDragging) {
      this.position = {
        x: event.clientX - this.dragOffset.x,
        y: event.clientY - this.dragOffset.y,
      };
    }
  }

  onMouseUp(event: MouseEvent) {
  
    const canvasElements = this.pdfCanvases.toArray();
  
    for (let i = 0; i < canvasElements.length; i++) {
      const canvas = canvasElements[i].nativeElement;
      const rect = canvas.getBoundingClientRect();
  
      const x = event.clientX - rect.left; 
      const y = event.clientY - rect.top;
        
      const canvasWidth = rect.width;
      const canvasHeight = rect.height;

      if (
        this.isDragging &&
        event.clientX  >= rect.left &&
        event.clientX <= rect.right &&
        event.clientY >= rect.top &&
        event.clientY <= rect.bottom
      ) {
              
        const xPercentage = (x  / canvasWidth) * 100;
        const yPercentage = Math.abs(((y / canvasHeight) * 100) - 100);
  
        this.signaturePosition.emit({
          outOfScopePdf: false,
          signaturePosition: {
            page: i,
            xPercentage,
            yPercentage
          }
        });

        break;
      }
      else {
        this.emitSignatureOutOfScopeEvent();
      }
    }

    this.onMouseMove(event);
    this.isDragging = false;
  }

  emitSignatureOutOfScopeEvent(): void {
    this.signaturePosition.emit({
      outOfScopePdf: true,
      signaturePosition: {
        page: 0,
        xPercentage: 0,
        yPercentage: 0,
      },
    });
  }

  convertBase64ToArrayBuffer(base64: string): ArrayBuffer {
  
    const binaryString = atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes.buffer;
  }
}
