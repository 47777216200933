import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formVacationType } from '../utilities/form-types-groups';
import FormRequest from '../common/formRequest';
import { AutoridadeEnum } from 'src/app/Enums/autoridadeTypeEnum';

@Component({
  selector: 'app-form-vacation',
  templateUrl: './form-vacation.component.html',
  styleUrls: ['./form-vacation.component.scss']
})
export class FormVacationComponent extends FormRequest implements OnInit {

  public isAdress = false;
  public autoridades: { label: string, value: string }[] = [
      {
        label: "Secretário(a) de Infraestrutura",
        value: AutoridadeEnum.SECRETARIO_INFRAESTRUTURA
      },
      {
        label: "Secretário(a) de Educação",
        value: AutoridadeEnum.SECRETARIO_EDUCACAO
      },
      {
        label: "Secretário(a) Regional Pecém",
        value: AutoridadeEnum.SECRETARIO_REGEGIONAL_PECEM,
  
      },
      {
        label: "Prefeito Municipal",
        value: AutoridadeEnum.PREFEITO_MUNICIPAL
      },
      {
        label: "Ouvidor(a)",
        value: AutoridadeEnum.OUVIDOR
      },
      {
        label: "Secretário(a) de Saúde",
        value: AutoridadeEnum.SECRETARIO_SAUDE
      },
      {
        label: "Controlador(a)",
        value: AutoridadeEnum.CONTROLADOR
      },
      {
        label: "Secretário(a) de Administração",
        value: AutoridadeEnum.SECRETARIO_ADMINISTRACAO
      },
      {
        label: "Secretário(a) de Governo",
        value: AutoridadeEnum.SECRETARIO_GOVERNO
      },
      {
        label: "Secretário(a) de Trab. e Des.Social",
        value: AutoridadeEnum.SECRETARIO_TRABALHO_SOCIAL
      },
      {
        label: "Procurador(a) Geral",
        value: AutoridadeEnum.PROCURADOR_GERAL
      },
      {
        label: "Secretário(a) Geral",
        value: AutoridadeEnum.SECRETARIO_GERAL
      },
      {
        label: "Secretário(a) de Finanças",
        value: AutoridadeEnum.SECRETARIO_FINANCAS
      },
      {
        label: "Coordenador(a) do FMPS",
        value: AutoridadeEnum.COORDENADOR_FMPS
      },
      {
        label: "Outros",
        value: AutoridadeEnum.OUTROS
      }
  ];

  constructor(public fb: FormBuilder) {
    super(fb, null);
    this.formType = formVacationType;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      requerente: this.fb.group({
        rg: ['', Validators.required],
        orgaoExpedidor: ['', Validators.required],
        uf: ['', Validators.required],
        localidade: ['', Validators.required],
        logradouro: ['', Validators.required],
        numero: ['', Validators.required],
        complemento: [''],
        bairro: ['', Validators.required],
        CEP: ['', Validators.required],
        telefone: ['', [Validators.required]],
        dataNascimento: ['', Validators.required],
        cargoFuncao: ['', Validators.required],
        classe: [''],
        lotacao: ['', Validators.required],
        unidadeExercicio: [''],
        dataEfetivoExercicio: [''],
        autoridadeRequerida: [''],
        periodoAquisitivo: ['', Validators.required] 
      }),
      formVariants: this.fb.group({
        SOL_Descricao: ['', Validators.required],
        termoVeracidade: [false, Validators.requiredTrue] 
      })
    });

    this.form.get('formVariants.termoVeracidade')?.valueChanges.subscribe((checked) => {
      if (checked) {
        this.markAllAsTouched();
      }
    });

    this.formChanges();
  }

  isInvalid(controlName: string, group: string = 'requerente'): boolean {
    const control = this.form.get(`${group}.${controlName}`);
    return control?.invalid && (control?.touched || control?.dirty);
  }

  markAllAsTouched() {
    this.form.markAllAsTouched();    
    this.isAdress = true;
  }

  getErrorMessage(controlName: string): string | null {
    const control = this.form.get(controlName);
    if (control && control.touched && control.invalid) {
      if (control.errors?.['required']) {
        return 'Este campo é obrigatório';
      }
      if (control.errors?.['requiredTrue']) {
        return 'Você precisa concordar com o termo';
      }
    }
    return null;
  }

  setEndereco(event) {
    this.form.get('requerente').patchValue({...event})
  }

}
