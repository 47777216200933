import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeWhile, takeUntil, debounceTime } from 'rxjs/operators';
import { DownloadGlobal, GlobalStateService } from 'src/app/app-core/services/global-state.service';

@Component({
  selector: 'dialog-download',
  templateUrl: './dialog-download.component.html',
  styleUrls: ['./dialog-download.component.scss']
})
export class DialogDownloadComponent implements OnInit {

  public list: DownloadGlobal[] = [];
  public upload = HttpEventType.UploadProgress;
  public download = HttpEventType.DownloadProgress;
  public styleDialog = { width: '25rem', height: '10rem', opacity: 0.9 }

  private destroyed$ = new Subject<void>();

  constructor(private globalStateService: GlobalStateService) { }

  ngOnInit(): void {
    this.globalStateService
      .downloads()
      .pipe(
        debounceTime(100),
        takeUntil(this.destroyed$)
      )
      .subscribe({
        next: (values: DownloadGlobal[]) => {
          this.list = values.reverse();
        },
        error: (err) => console.error("Erro ao subscrever downloads$: ", err),
      });
  }
  
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
