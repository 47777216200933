import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GerinusService } from '../lib/gerinus.service';
import { RequestService } from './request.service';
import { OrganizationService } from './organization.service';
import { ProcessService } from './process.service';
import { UserService } from './user.service';
import { GlobalService } from '../lib/global.service';
import { Observable, of } from 'rxjs';
import { ApplicantRequestService } from './applicant-request.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DatePipe } from '@angular/common';

@Injectable()
export class DashboardService extends GerinusService {
    constructor(
        public http: HttpClient,
        public requestService: RequestService,
        public processService: ProcessService,
        public organizationService: OrganizationService,
        public userService: UserService,
        public global: GlobalService,
        public applicantRequestService: ApplicantRequestService
    ) {
        super(http);
    }

    async getTotal(entity: string, where: string = ''): Promise<number> {
        return new Promise<number>((resolve) => {
            if (this[entity]) {
                this[entity].listAll(where, 0).subscribe({
                    next: (response: any) => {
                        const total = response.data.length;
                        resolve(total);
                    },
                });
            } else {
                resolve(0);
            }
        });
    }

    getTotalObservable(entity: string, where: string = ''): Observable<any> {
        return this[entity].listAll(where, 0)
    }

    getTotalObservableScreeningProcess(entity: string, where: string = ''): Observable<any> {
        return this[entity].listAll(where, 0)
    }

    public generateDashboardListPDF(requestsAmount: number, processAmount: number, organizationsAmount: number, usersAmount: number, screeningProcess: number, unassignedProcesses: number) {
        const doc = new jsPDF('p', 'mm', 'a4');
        const datePipe = new DatePipe('pt-BR');
        const currentDate = datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss');
        const titles = ['SOLICITAÇÕES', 'PROCESSOS', 'ÓRGÃOS', 'USUÁRIOS', 'PROCESSOS EM TRIAGEM', 'PROCESSOS SEM ATRIBUIÇÃO'];
        const title = ['RELATÓRIO DE DASHBOARD'];
        const data = [requestsAmount, processAmount, organizationsAmount, usersAmount, screeningProcess, unassignedProcesses]

        autoTable(doc, {
            head: [title],
            startY: 16,
            margin: { top: 20 },
            styles : {
                halign: 'center',
                valign: 'middle',
                fillColor: '#0a2156',
            },
            headStyles: {
                cellPadding: 0.5,
                fontSize: 8,
            }
        });

        autoTable(doc, {
            head: [titles],
            body: [data],
            startY: 20,
            margin: { top: 20 },
            styles : {
                valign: 'middle',
                fontSize: 10,
            },
            headStyles: {
                fontSize: 6,
                cellPadding: 1,
            },
            bodyStyles: {
                fontSize: 6,
            },
        });

        const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        doc.setFontSize(8);
        doc.text(`Gerado em: ${currentDate}`, doc.internal.pageSize.width - 131, pageHeight - 10);
        doc.save(`${title}.pdf`);
    }
}
