import { Injectable } from '@angular/core';
import { GerinusService } from '../lib/gerinus.service';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InternalProcessAttachmentsService extends GerinusService {

  constructor(public http: HttpClient) {
    super(http);
    this.entityName = "processo_interno_anexo"
    this.idField = "PIA_CodigoProcessoAnexo"
    this.model = "ProcessoInternoAnexoModel"
  }

  addComplementaryFiles(internalProcessId: number, data: any): Observable<any> {
    return this.http.post(this.apiURL() + '/api/internal-process/' + internalProcessId + '/add-complementary-files', data, this.headers())
      .pipe(
        catchError(this.handleError)
      )
  }

  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error);
    return throwError(() => new Error('Something went wrong; please try again later.'));
  }

  cancelComplementaryFiles(processId: number, data: any) {
    return this.http.post(this.apiURL() + '/api/internal-process/' + processId + '/cancel-files', { data }, this.headers())
      .pipe(
        catchError(this.handleError)
      )
  }

}
