import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GerinusToolsModule } from 'src/app/app-core/lib/gerinus-tools.module';
import { AppCoreModule } from 'src/app/app-core/app-core.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { BlockUIModule } from 'ng-block-ui';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { AreaSatisfactionComponent } from 'src/app/pages/area-satisfaction/area-satisfaction.component';



@NgModule({
  imports: [
    CommonModule,
    GerinusToolsModule,
    AppCoreModule,
    ComponentsModule,
    AutoCompleteModule,
    SidebarModule,
    ToastModule,
    BlockUIModule,
    DialogModule,
    CalendarModule,
    MultiSelectModule,
    CheckboxModule,
    DropdownModule,
  ],
  declarations: [AreaSatisfactionComponent],
  exports: [GerinusToolsModule],
})
export class AreaSatisfactionModule { }
