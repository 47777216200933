import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface DownloadGlobal {
  id: number;
  type: number;
  percent: number;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalStateService {

  private downloadsSubject = new BehaviorSubject<DownloadGlobal[]>([]);
  private idCounter = 0;

  private generateUniqueId(): number {
    this.idCounter += 1;
    return this.idCounter;
  }

  addDownload(download: Omit<DownloadGlobal, 'id'>) {
    const id = this.generateUniqueId(); 
    const newDownload = { ...download, id };
    const currentDownloads = this.downloadsSubject.value;
    this.downloadsSubject.next([...currentDownloads, newDownload]);

    return id;
  }

  updateDownload(id: number, type: number, percent: number) {
    const currentDownloads = this.downloadsSubject.value.map(download =>
      download.id === id
        ? { ...download, type, percent }
        : download
    );
    this.downloadsSubject.next(currentDownloads);
  }

  removeDownload(id: number) {
    const currentDownloads = this.downloadsSubject.value.filter(download => download.id !== id);
    this.downloadsSubject.next(currentDownloads);
  }

  clearDownloads() {
    this.downloadsSubject.next([]);
  }

  downloads() {
    return this.downloadsSubject.asObservable()
  }

  getEventsRequest(event: HttpEvent<any>, idDownload: number) {    
    switch (event.type) {
      case HttpEventType.User:
          break;
      case HttpEventType.UploadProgress:
          if (event.total) {
              const percent = Math.round((event.loaded / event.total) * 100);
              this.updateDownload(idDownload, event.type, percent)
          }
          break;
      case HttpEventType.DownloadProgress:
          if (event.total) {
            const percentDone = Math.round((event.loaded / event.total) * 100);
            this.updateDownload(idDownload, event.type, percentDone)
          }
          break;
      case HttpEventType.Response:
          this.removeDownload(idDownload);
          break;
      default:
        break;
      }
  }

}
