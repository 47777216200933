import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { format } from 'date-fns';
import { PrimeNGConfig } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { GerinusBD } from 'src/app/app-core/lib/gerinus-bd.component';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { AreaSatisfactionService } from 'src/app/app-core/services/area-satisfaction.service';
import { FilterService, IAvaliationFilters } from 'src/app/app-core/services/filter.service';
import { UserService } from 'src/app/app-core/services/user.service';
import { IAvaliationFoundResponseSchema } from 'src/app/interfaces/area-satisfaction.interface';

@Component({
  selector: 'app-area-satisfaction',
  templateUrl: './area-satisfaction.component.html',
  styleUrls: ['./area-satisfaction.component.scss'],
  providers: [AreaSatisfactionService, GlobalService, FilterService, UserService]
})
export class AreaSatisfactionComponent extends GerinusBD implements OnInit {

  public customTitle: string = 'Avaliação do Usuário';
  public satisfactionList: any[] = [];
  public averageRating: number = 0;
  public actualMonthSatisfactions: number = 0;
  public countMonthSatisfactions: number = 0;
  public totalCount: number = 0;
  public ratingDistribution: { PRA_Avaliacao: number, count: number }[] = [];
  public maxCount: number = 0;
  public selectedEvaluation: IAvaliationFoundResponseSchema;
  public visible: boolean = false;
  public trendValue = 0;
  public currentDate = new Date();

  //Filtros
  public isFiltersModalOpen: boolean = false;
  public isFiltersModalResponsiveOpen: boolean = false;
  public createdAtRange: Date[] | undefined;
  public avaliationFilters: IAvaliationFilters;
  public owner: string;
  public avaliation: number;
  public processAvaliation: number;
  public createdAFilter;


  constructor(
    public global: GlobalService,
    public userService: UserService,
    private satisfactionService: AreaSatisfactionService,
    private config: PrimeNGConfig,
    private activatedRoute: ActivatedRoute,
    private filterService: FilterService,
    private router: Router,

  ) {
    super(global, satisfactionService);
  }

  ngOnInit(): void {
    this.listAll();
    this.fetchAverageRating();
    this.fetchTotalCount();
    this.fetchRatingDistribution();
    this.fetchTrend();
    this.config.setTranslation({
      apply: 'Aplicar',
      clear: 'Limpar',
      accept: 'Sim',
      reject: 'Não',
      firstDayOfWeek: 0,
      dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
          'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      today: 'Hoje'
    });

    this.activatedRoute.queryParams.subscribe(params => {
    this.owner = params['owner'] || '';
    this.avaliation = params['avaliation'] || '';
    this.processAvaliation = params['processAvaliation'] || '';

    if (typeof params['createdAtRange'] === 'string' && params['createdAtRange'] !== '') {
      this.createdAtRange = params['createdAtRange'].split(',').map(date => new Date(date));
    }
    else {
      this.createdAtRange = [];
    }
    });
  }

  public listCondition() {
    let conditions = '';

    const additionalFilters = this.filter();

    if (typeof additionalFilters === 'string') {
      conditions = additionalFilters;
    }

    return conditions;
  }

  fetchAllAvaliationsFiltered() {
    this.listAll();

    this.isFiltersModalOpen = false;
    this.isFiltersModalResponsiveOpen = false;
  }

  filter() {
    this.avaliationFilters = {
         owner: this.owner,
         avaliation: this.avaliation,
         processAvaliation: this.processAvaliation,
         createdAtRange: 
    this.createdAtRange
        .filter(date => date != null)
        .map(date => format(date, 'yyyy-MM-dd'))
    }

    const filterConditions = this.filterService.getAvaliationFilterConditions(this.avaliationFilters);


    this.router.navigate([], {
         relativeTo: this.activatedRoute,
         queryParams: this.avaliationFilters,
         queryParamsHandling: 'merge',
    });

    return filterConditions;
  }

  clearFilters() {
    this.owner = null,
    this.avaliation = null,
    this.processAvaliation = null,
    this.createdAtRange = null,

    this.router.navigate([], {
         relativeTo: this.activatedRoute,
         queryParams: {},
    });

    this.listAll();

    this.isFiltersModalOpen = false;
    this.isFiltersModalResponsiveOpen = false;
  }

  get isClearFiltersButtonVisible(): boolean {
    return !!(
      this.avaliation ||
      this.processAvaliation ||
     (this.createdAtRange && this.createdAtRange.length > 0) ||
      this.owner
    );
  }

  public getStars(rating: number): number[] {
    return Array(Math.round(rating)).fill(0);
  }

  private fetchTrend() {
      this.satisfactionService.getTrend().subscribe((response) => {
      this.trendValue = response;
    })
  } 

  private fetchAverageRating(): void {
  
    const observables$ = {
          allSatisfactions: this.satisfactionService.getAverageRating(false),
          actualMonthSatisfactions: this.satisfactionService.getAverageRating(true),
    };

    forkJoin(observables$).subscribe(({allSatisfactions, actualMonthSatisfactions}: any) => {
      this.averageRating = allSatisfactions.average;
      this.actualMonthSatisfactions = actualMonthSatisfactions.average;
    });
  }

  private fetchTotalCount(): void {

    const observables$ = {
          allCountSatisfactions: this.satisfactionService.getCount(false),
          actualMonthSatisfactions: this.satisfactionService.getCount(true),
    };

    forkJoin(observables$).subscribe(({allCountSatisfactions, actualMonthSatisfactions}: any) => {
      this.totalCount = allCountSatisfactions.total;
      this.countMonthSatisfactions = actualMonthSatisfactions.total;
    });
  }

  private fetchRatingDistribution(): void {
      this.satisfactionService.getRatingDistribution().subscribe((data) => {
      this.ratingDistribution = data.ratingDistribution;
      this.maxCount = data.maxCount;
    });
  }

  showDialog(satisfaction: IAvaliationFoundResponseSchema) {
    this.selectedEvaluation = satisfaction;
    this.visible = true;
  }

  public closeModal(): void {
    this.selectedEvaluation = null;
    this.visible = false;
  }

}
