import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BlockUIModule } from 'ng-block-ui';

import { GerinusToolsModule } from '../app-core/lib/gerinus-tools.module';

import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { MenuItemComponent } from './sidebar/menu-item/menu-item.component';
import { DropzoneComponent } from './dropzone/dropzone.component';
import { LoginGovBrComponent } from './login-gov-br/login-gov-br.component';
import { RequestModalComponent } from './modal-info/modal-info.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { AvatarComponent } from './avatar/avatar.component';
import { ViewPublicProcessComponent } from '../pages/view-public-process/view-public-process.component';
import { DashboardTriagemComponent } from './dashboard-triagem/dashboard-triagem.component';
import { DashboardNormalComponent } from './dashboard-cidadao/dashboard-cidadao.component';
import { DashboardDiretorDepartamentoComponent } from './dashboard-diretor-departamento/dashboard-diretor-departamento.component';
import { CollapseComponent } from './collapse/collapse.component';
import { CadComponent } from '../app-core/layout/cad.component';
import { EmptyDataComponent } from './empty-data/empty-data.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectUfEstadoComponent } from './select-uf-estado/select-uf-estado.component';
import { ViewDocumentFormComponent } from './view-document-form/view-document-form.component';
import { EnderecoFormComponent } from './endereco-form/endereco-form.component';
import { SignGovComponent } from './sign-gov/sign-gov.component';
import { PlaceholderSignComponent } from './sign-gov/components/placeholder-sign/placeholder-sign.component';
import { AccountLevelModalComponent } from './account-level-modal/account-level-modal.component';
import { SidebarModule } from 'primeng/sidebar';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { DashboardOrganizationComponent } from './dashboard-organization/dashboard-organization.component';
import { DashboardAdminComponent } from './dashboard-admin/dashboard-admin.component';

const components = [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    MenuItemComponent,
    DropzoneComponent,
    LoginGovBrComponent,
    RequestModalComponent,
    AutocompleteComponent,
    AvatarComponent,
    ViewPublicProcessComponent,
    DashboardAdminComponent,
    DashboardTriagemComponent,
    DashboardNormalComponent,
    DashboardDiretorDepartamentoComponent,
    DashboardOrganizationComponent,
    CollapseComponent,
    CadComponent,
    EmptyDataComponent,
    SelectUfEstadoComponent,
    EnderecoFormComponent,
    ViewDocumentFormComponent,
    SignGovComponent,
    PlaceholderSignComponent,
    AccountLevelModalComponent
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        GerinusToolsModule,
        BlockUIModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        SidebarModule,
        CalendarModule,
        DropdownModule
    ],
    declarations: components,
    exports: [GerinusToolsModule, ...components],
})
export class ComponentsModule {}
