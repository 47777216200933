import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { GlobalService } from 'src/app/app-core/lib/global.service';
import { DashboardService } from 'src/app/app-core/services/dashboard.service';
import { DepartmentService } from 'src/app/app-core/services/department.service';
import { UserService } from 'src/app/app-core/services/user.service';
import { OrganizationService } from 'src/app/app-core/services/organization.service';
import { IDepartment } from 'src/app/interfaces/department';
import { IListScopeDepartmentResponse } from 'src/app/interfaces/listScope/listScopeDepartmentResponse';
import { FilterService, IDashboardFilters } from 'src/app/app-core/services/filter.service';
import { Dropdown } from 'primeng/dropdown';
import { IOrganization } from 'src/app/interfaces/organization';
import { IListScopeOrganizationResponse } from 'src/app/interfaces/listScope/listScopeOrganizationResponse';
import { GerinusBD } from 'src/app/app-core/lib/gerinus-bd.component';

@Component({
  selector: 'dashboard-organization',
  templateUrl: './dashboard-organization.component.html',
  styleUrls: ['../../pages/dashboard/dashboard.component.scss', './dashboard-organization.component.scss'],
  providers: [
    DashboardService,
    UserService,
    FilterService,
    OrganizationService
  ],
})
export class DashboardOrganizationComponent extends GerinusBD implements OnInit {

  constructor(
    public global: GlobalService,
    private activatedRoute: ActivatedRoute,
    public dashboardService: DashboardService,
    private filterService: FilterService,
    private router: Router,
    public departmentService: DepartmentService,
    public userService: UserService,
    public organizationService: OrganizationService,
  ) {
    super(global, dashboardService);
  }

  public requestsAmount: number;
  public processAmount: number;
  public organizationsAmount: number;
  public usersAmount: number;

  //FILTROS
  public isFiltersModalOpen: boolean = false;
  public departments: IDepartment[];
  public selectedDepartments: any;
  public dashboardFilters: IDashboardFilters
  public organizations: IOrganization[] | undefined;
  public selectedOrganization: string;
  public organizationSelectedName = '';
  public departmentSelectedName = '';

  async ngOnInit() {
    this.getData();
    this.getDepartments();
    this.getOrganizations();
  }

  public getData() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.selectedDepartments = params['department'] || '';
      this.global.user().OR_CodigoOrgao = params['organization'] || '';

      let whereProcess = '';
      let whereOrganization = '';
      let whereUsers = '';
      let whereUnassignedProcess = '';
      let whereScreeningProcess = '';
      let whereProcessDepartments = '';
      let whereProcessDepartmentsScreeningProcess = '';
      let whereProcessDepartmentsUnassignedProcess = '';

      if (this.global.user().OR_CodigoOrgao) {
        whereProcess += `AND "SOL_CodigoOrgao" = ${this.global.user().OR_CodigoOrgao}`;
        whereOrganization += `"OR_CodigoOrgao" = ${this.global.user().OR_CodigoOrgao}`;
        whereUsers += `"USR_CodigoOrgao" = ${this.global.user().OR_CodigoOrgao}`;
        whereUnassignedProcess += `"SOL_CodigoOrgao" = ${this.global.user().OR_CodigoOrgao}`;
        whereScreeningProcess += `"SOL_CodigoOrgao" = ${this.global.user().OR_CodigoOrgao}`;
      }
      if (this.selectedDepartments) {
          whereProcessDepartments += `AND "PRT_DepartamentoAtual" = ${this.selectedDepartments}`;
          whereUsers += ` AND "PE_CodigoDepartamento" = ${this.selectedDepartments}`;
          whereProcessDepartmentsScreeningProcess += ` AND "PRT_DepartamentoAtual" = ${this.selectedDepartments}`;
          whereProcessDepartmentsUnassignedProcess += `AND "PRT_DepartamentoAtual" = ${this.global.user().OR_CodigoOrgao}`;
      }

      const finalWhereScreeningProcess = whereProcessDepartmentsScreeningProcess && whereScreeningProcess
          ? `${whereScreeningProcess} ${whereProcessDepartmentsScreeningProcess}`
          : whereScreeningProcess + whereProcessDepartmentsScreeningProcess;

      const finalWhereUnassignedProcess = whereProcessDepartmentsUnassignedProcess && whereUnassignedProcess
          ? `${whereUnassignedProcess} ${whereProcessDepartmentsUnassignedProcess} AND `
          : whereProcessDepartmentsUnassignedProcess;

      this.getCount(
          'processAmount',
          'processService',
          `"PRT_DataFim" IS NULL ` + whereProcess + whereProcessDepartments,
      );

      this.getCount(
          'requestsAmount',
          'requestService',
          `"SOL_CodigoSolicitacao" IS NOT NULL ` + whereProcess
      );

      this.getCountScreeningUnassignedProcess(
          'screeningProcess',
          'applicantRequestService',
          finalWhereScreeningProcess
      );

      this.getCountScreeningUnassignedProcess(
          'unassignedProcesses',
          'processService',
          whereProcessDepartmentsUnassignedProcess ? finalWhereUnassignedProcess + `"PRC_CodigoResponsavel" IS NULL` : `"PRC_CodigoResponsavel" IS NULL`
      )
    })
  }

  public getCountScreeningUnassignedProcess(variableName: string, service: string, where = '') {
    this.dashboardService.getTotalObservableScreeningProcess(
        service,
        where,
    ).subscribe({
        next: (response: any) => {
            this[variableName] = response.data.length
        }
    });
  }

  public getCount(variableName: string, service: string, where = '') {
      this.dashboardService.getTotalObservable(
          service,
          where,
      ).subscribe({
          next: (response: any) => {
              this[variableName] = response.data.length
          }
      });
  }

  public filter() {
    this.dashboardFilters = {
        department: this.selectedDepartments,
        organization: this.global.user().OR_CodigoOrgao,
    };

    const filterConditions = this.filterService.getDashboardFilterConditions(this.dashboardFilters);

    this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: this.dashboardFilters,
        queryParamsHandling: 'merge',
    });

    this.listAll();

    return filterConditions;
  }

  clearFilters() {
    this.selectedDepartments = null,
    this.global.user().OR_CodigoOrgao = null

    this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {},
    });

    this.listAll();

    this.isFiltersModalOpen = false;
  }

  fetchAllDashboardFiltered() {
    this.listAll();
    this.filter();
    this.isFiltersModalOpen = false;
  }

  getDepartments() {
    this.departmentService.listAll(`"DEP_CodigoOrgao" = ${this.global.user().OR_CodigoOrgao}`, 0).subscribe({
        next: (response: IListScopeDepartmentResponse) => {
            this.departments = response.data;

            if (
                this.userService.isAdmin() ||
                this.userService.isDiretorDepartamento() ||
                this.userService.isSecretario() ||
                this.userService.isMembroDepartamento()
            ) {
                this.departments = this.departments.filter(departments => departments.DEP_CodigoOrgao === this.global.user().USR_CodigoOrgao)
            }
        }
    })
  }

  getOrganizations() {
    this.organizationService.listAll('', 0).subscribe({
        next: (response: IListScopeOrganizationResponse) => {
            this.organizations = response.data
        }
    })
  }

  public listCondition() {
    let conditions = '';

    const additionalFilters = this.filter();

    if (additionalFilters) {
        if (conditions === '') {
            conditions = additionalFilters;
        }
        else {
            conditions += ` AND ${additionalFilters}`;
        }
    }

    return conditions;
  }

  restoreFilterValues() {
    const org = this.organizations.find(org => org.OR_CodigoOrgao == this.global.user().OR_CodigoOrgao);
    this.organizationSelectedName = org ? org.OR_Descricao : '';

    const dep = this.departments.find( dep => dep.DEP_CodigoDepartamento == parseInt(this.selectedDepartments, 10) );
    this.departmentSelectedName = dep ? dep.DEP_Descricao : '';
    
    this.global.user().OR_CodigoOrgao = org?.OR_CodigoOrgao || null;
    this.selectedDepartments = dep?.DEP_CodigoDepartamento || null;
  }

  changeOrganization() {
    const organizationName = this.organizations.find( org => org.OR_CodigoOrgao == this.global.user().OR_CodigoOrgao );
    this.organizationSelectedName = organizationName.OR_Descricao;
  }

  changeDepartaments() {
      const dapartmentName = this.departments.find( dep => dep.DEP_CodigoDepartamento == parseInt(this.selectedDepartments, 10) );
      this.departmentSelectedName = dapartmentName.DEP_Descricao;
  }
}
