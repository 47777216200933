import { AfterViewInit, Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { UserService } from 'src/app/app-core/services/user.service';
import { AccountLevelGovBrEnum } from 'src/app/Enums/accountLevelGovBrEnum';

@Component({
  selector: 'account-level-modal',
  templateUrl: './account-level-modal.component.html',
  styleUrls: ['./account-level-modal.component.scss'],
  providers: [UserService]
})
export class AccountLevelModalComponent implements AfterViewInit {

  public modalName = "account-level"
  constructor(
    private userService: UserService,
    private global: GlobalService
  ) { }

  ngAfterViewInit() {
    this.checkAccountLevel();
  }

  closeModalInfo() {
    this.global.closeModalInfo(this.modalName);
    window.history.back();
  }

  checkAccountLevel() {
    const accountLevel = this.userService.getAccountLevel();
    if (accountLevel == AccountLevelGovBrEnum.BRONZE) {
      this.global.showModalInfo(this.modalName);
    }
  }

}
