import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlockUIModule } from 'ng-block-ui';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppCoreModule } from '../../app-core.module';
import { RequestsRoutingModule } from './requests-routing.module';
import { GerinusToolsModule } from '../../lib/gerinus-tools.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { RequestsComponent } from '../../../pages/requests/requests.component';
import { ViewCorrectionRequestsComponent } from '../../../pages/view-correction-requests/view-correction-requests.component';
import { NewRequestComponent } from '../../../pages/new-request/new-request.component';
import { ViewPublicRequestsComponent } from 'src/app/pages/view-public-requests/view-public-requests.component';
import { RequestService } from '../../services/request.service';
import { FormsModule } from '../forms/forms.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AttachmentsRequestComponent } from 'src/app/pages/new-request/components/attachments-request/attachments-request.component';
import { UserDataRequestComponent } from 'src/app/pages/new-request/components/user-data-request/user-data-request.component';
import { SafePipe } from 'src/app/safe.pipe'
import { SharedModule } from '../../shared/shared.module';
import { ToastModule } from 'primeng/toast';
import { SidebarModule } from 'primeng/sidebar';
import { CalendarModule } from 'primeng/calendar';
import {MultiSelectModule} from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
    imports: [
        CommonModule,
        GerinusToolsModule,
        AppCoreModule,
        ComponentsModule,
        RequestsRoutingModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        SidebarModule,
        CalendarModule,
        BlockUIModule,
        SharedModule,
        ToastModule,
        MultiSelectModule,
        DropdownModule,
        DialogModule,
        ProgressSpinnerModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    declarations: [
        RequestsComponent,
        ViewCorrectionRequestsComponent,
        NewRequestComponent,
        ViewPublicRequestsComponent,
        UserDataRequestComponent,
        AttachmentsRequestComponent,
        SafePipe
    ],
    providers: [RequestService, { provide: LOCALE_ID, useValue: 'pt-BR' }],
    exports: [GerinusToolsModule],
})
export class RequestsModule { }
