import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';
import { Observable } from 'rxjs';

export interface IUpload {
    file: File,
    organization: string
}

@Injectable()
export class UploadService extends GerinusService {
    constructor(public http: HttpClient) {
        super(http);
        this.model = 'AppModel';
    }

    upload({ file, organization }: IUpload) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('organization', organization)

        return this.http.post(this.apiURL() + '/api/app/file', formData, this.headers(true));
    }

    download(name: string, organization?: string) {
        return this.http.post(this.apiURL() + '/api/app/findAndDownloadFile/', { name, organization }, this.headers(true));
    }

    downloadAndReturnArrayBuffer(url: string) {
        return this.http.post(this.apiURL() + '/api/app/download-buffer-file/', { url } , { ...this.headers(), responseType: 'arraybuffer' });
    }

    deleteFile(name: string, table: string, organization?: string): Observable<any> {
        let url = `${this.apiURL()}/api/app/delete-file?name=${encodeURIComponent(name)}&table=${encodeURIComponent(table)}`;
        if (organization) url + `&organization=${encodeURIComponent(organization)}`

        return this.http.delete(url, this.headers());
    }
}
