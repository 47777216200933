import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { DocumentSignatureService } from 'src/app/app-core/services/document-signature.service';
import { UserService } from 'src/app/app-core/services/user.service';
import { AccountLevelGovBrEnum } from 'src/app/Enums/accountLevelGovBrEnum';
import { IPdfData, ISignaturePlaceholder, ISignaturePosition } from 'src/app/interfaces/signature-gov.interfaces';
import { environment } from 'src/environments/environment';

enum StatusErrorSignGov {
  IDENTIDADE_PRATA_OUTRO = 403
}

@Component({
  selector: 'app-sign-gov',
  templateUrl: './sign-gov.component.html',
  styleUrls: ['./sign-gov.component.scss'],
  providers: [DocumentSignatureService, UserService]
})
export class SignGovComponent implements OnInit {

  @Input() disabled: boolean;
  @Input() pdfData: IPdfData;

  @Output() signData = new EventEmitter<boolean>(false);

  public signatureOutOfScopePdf: boolean;
  public documentBase64Sign: string;
  public wasSign = false;
  public isLoadingSign = false;
  public modalName = "assinatura-error";
  private signaturePosition: ISignaturePosition;

  constructor(
    private global: GlobalService,
    private ngZone: NgZone,
    private documentSignatureService: DocumentSignatureService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    window.addEventListener('message', this.receiveMessage.bind(this), false);
    this.checkAccountLevel();
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.receiveMessage.bind(this));
  }

  receiveSignaturePosition(signaturePlaceholder: ISignaturePlaceholder) {
    this.signaturePosition = signaturePlaceholder.signaturePosition;
    this.signatureOutOfScopePdf = signaturePlaceholder.outOfScopePdf;
  }

  public signGovBr() {
    const signUrl = `${environment.govBR.signUrl}/authorize?response_type=code&redirect_uri=${encodeURIComponent(environment.govBR.returnSignUrl)}&scope=sign&client_id=${environment.govBR.signClientID}`;
    const width = 800;
    const height = 600;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    const popup = window.open(signUrl, '_blank', `width=${width},height=${height},top=${top},left=${left}`);

    const interval = setInterval(() => {
      try {
        if (popup && popup.location.href.includes('code=')) {
          const url = new URL(popup.location.href);
          const code = url.searchParams.get('code');
          popup.close();
          clearInterval(interval);
          this.ngZone.run(() => {
            
            if (code) {
              this.isLoadingSign = true;
              this.documentSignatureService.signatureAndSaveDocument({
                code,
                fileName: this.pdfData.pdfFileName,
                pdfBase64: this.pdfData.pdfBase64,
                signaturePosition: this.signaturePosition
              })
              .subscribe({
                next: (response: any) => {
                  this.documentBase64Sign = response.base64Document;
                  this.wasSign = true;
                  this.signData.emit(response);
                },
                error: (error) => {
                  if (error.status == StatusErrorSignGov.IDENTIDADE_PRATA_OUTRO) {
                    this.global.showModalInfo(this.modalName);
                  }
                },
                complete: () => {
                  this.isLoadingSign = false;
                },
              })
            }
          });
        }
      } catch (error) {
        
      }
    }, 1000);
  }

  checkAccountLevel() {
    const accountLevel = this.userService.getAccountLevel();
    if (accountLevel == AccountLevelGovBrEnum.BRONZE) {
      this.global.showModalInfo(this.modalName);
    }
  }

  checkScopePlaceholder(event) {
    this.signatureOutOfScopePdf = event;
  }

  closeModalInfo() {
    this.global.closeModalInfo(this.modalName);
  }

  private receiveMessage(event: MessageEvent) {
    if (event.origin === environment.govBR.returnSignUrl) {
      const code = event.data.code;
      if (code) {
        console.log('Código de autorização recebido:', code);
      }
    }
  }

}
