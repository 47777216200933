import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import $ from 'jquery';
import { OrganizationEnum } from 'src/app/Enums/organizationEnum';
import { UserService } from 'src/app/app-core/services/user.service';

@Component({
    selector: 'app-attachments-request',
    templateUrl: './attachments-request.component.html',
    styleUrls: ['../../new-request.component.scss'],
    providers: [UserService]

})
export class AttachmentsRequestComponent implements OnInit, OnChanges {

    @Input() attachments: any[];
    @Input() orgao: any;
    @Input() subjectFormLink: string;
    @Input() subjectGuidelinesLink: string;
    @Input() haveSign: boolean;
    @Input() isOrgaoFiscalizador: boolean;

    public allowedFileMimeTypes: string[] = [
        'application/pdf',
        'application/x-zip-compressed',
        'application/x-rar-compressed',
        'application/zip',
        'application/vnd.rar',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/bmp',
        'image/webp',
        'image/tiff',
        'image/svg+xml'
    ];

    public mimeTypePdf = [
        'application/pdf',
        'application/zip',
        'application/vnd.rar',
        'application/x-zip-compressed',
        'application/x-rar-compressed',
    ]

    public entity: any;
    public modalTitle: string;
    public modalLink: string;
    public isAttached = false;
    public semurbNumber = OrganizationEnum.SEMURB;
    public documentRequiredName = "REQUERIMENTO"

    constructor(
        public global: GlobalService,
        public userService: UserService,
    ) {
        this.entity = this.defaults();
    }

    
    ngOnInit(): void {
        this.documentRequiredName = (this.isOrgaoFiscalizador)? "OFÍCIO" : "REQUERIMENTO"
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.attachments) {
           this.verifyHaveAttachments();
        }

        if (changes.isOrgaoFiscalizador) {
            this.documentRequiredName = (this.isOrgaoFiscalizador)? "OFÍCIO" : "REQUERIMENTO"
        }
    }

    public defaults() {
        return {
            documentos: {
                procuracao: [],
                requerimento: [],
                complementar: []
            }
        }
    }

    public getUploadedFile(event: any, attachment = null) {
        let file = event.file;

        if (file.typeDocument != 'complementar') {
            if (attachment) {
                file = {
                    ...file,
                    ANX_Obrigatorio: attachment.ANX_Obrigatorio
                };
            }
            if (file.typeDocument == this.documentRequiredName) {
                file = {
                    ...file,
                    ANX_Obrigatorio: true
                };
            }
            this.entity.documentos['requerimento'].push(file);
        }
        else {
            this.entity.documentos[file.typeDocument].push(file);
        }
        this.verifyUploadedAttachments();
    }

    private verifyUploadedAttachments() {
        for (const attachment of this.attachments) {
            if (attachment.ANX_Obrigatorio) {
                const list = this.entity.documentos.requerimento.filter((item) => item.typeDocument == attachment.ANX_Nome);
                (list.length == 0) ? this.isAttached = false : this.isAttached = true
            }
        }
        const requerimento = this.entity.documentos.requerimento.filter((item) => item.typeDocument == this.documentRequiredName);
        if (requerimento.length == 0 && !this.haveSign) {
            this.isAttached = false
        }

        if (requerimento.length == 0 && this.haveSign && this.isOrgaoFiscalizador) {
            this.isAttached = false
        }

    }

    public onDeleteFileUploaded(event: any) {
        let file = event;
        if (file.typeDocument != 'procuracao' && file.typeDocument != 'complementar') {
            for (let i = 0; i < this.entity.documentos.requerimento.length; i++) {
                if (file.typeDocument == this.entity.documentos.requerimento[i].typeDocument) {
                    this.entity.documentos['requerimento'].splice(i, 1);
                }
            }
        }
        else {
            const documentosArray = this.entity.documentos[event.typeDocument];

            if (documentosArray && documentosArray.length > event.index) {
                documentosArray.splice(event.index, 1);
            }
        }
        this.verifyUploadedAttachments();
    }

    public get organizationIsSemurb() {
        return this.semurbNumber === this.orgao?.OR_NumeroOrgao;
    }

    openFileModal(type: 'guidelines' | 'form') {
        const isPdfLink = (link) => link && link.toLowerCase().endsWith('.pdf');

        this.modalLink = type === 'guidelines' ? this.subjectGuidelinesLink : this.subjectFormLink;

        if (isPdfLink(this.modalLink)) {
            this.modalTitle = type === 'guidelines' ? 'Diretrizes' : 'Formulário';
            $('#file-dialog').modal('show');
        }
    }
    

    closeFileModal() {
        $('#file-dialog').modal('hide');
    }

    private verifyHaveAttachments() {
        if (this.attachments.length === 0 && this.haveSign) {
            this.isAttached = true;
        }
    }
}
