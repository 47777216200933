import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import MD5 from 'crypto-js/md5';

import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle';

@Injectable()
export class GlobalService {
    @BlockUI() blockUI: NgBlockUI;
    public message = 'Carregando...';
    public loading = false;

    public static companyID;

    public swal = swal;
    currentDate: Date = new Date();

    public modals: any = {};

    public theme: any = {
        loading: false,
        logo: null,
        bgLogon: null,
        favicon: null,
        primaryColor: '#91bd21',
    };

    constructor(
        public dialog: NgbModal,
        public router: Router,
    ) { }

    public user() {
        let user: any = localStorage.getItem('user');
        if (user) {
            user = JSON.parse(user);
        }
        return user;
    }

    public setTheme(theme) {
        let keys = Object.keys(theme);
        for (const key of keys) {
            this.theme[key] = theme[key];
        }

        const html = document.querySelector('html');
        const title = document.querySelector('title');
        const favicon = document.getElementById('favicon');
        html.style.setProperty('--primary-color', this.theme.primaryColor);
        if (this.theme.favicon) {
            if (this.theme.favicon.includes('/')) {
                favicon.setAttribute('href', this.theme.favicon);
            } else {
                favicon.setAttribute('href', this.apiURL() + '/_fileData/' + this.theme.favicon);
            }
        }
    }

    public apiURL(URL = null) {
        if (URL) {
            return environment.apiURL + '/' + URL;
        }
        return environment.apiURL;
    }

    public startLoading(message = null, showBlockUI = false) {
        if (message == null) {
            message = 'Carregando...';
        }

        if (showBlockUI) {
            this.blockUI.start(message);
        }
        else {
            this.loading = true;
            this.message = message;
        }
    }

    public stopLoading() {
        this.blockUI.stop();
        this.loading = false;
        this.message = 'Carregando...';
    }

    public loadImage(file, entity, imageField, tmpField) {
        entity[imageField] = file.target.files.item(0);

        // FileReader support
        if (FileReader) {
            var fr = new FileReader();
            fr.onload = () => {
                entity[tmpField] = fr.result;
            };
            fr.readAsDataURL(entity[imageField]);
        }
        // Not supported
        else {
            // fallback -- perhaps submit the input to an iframe and temporarily store
            // them on the server until the user's session ends.
        }
    }

    public copyToClipboard(str) {
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    public openModal(modal, id, params: any = null) {
        let def: any = {
            size: 'md',
            centered: true,
            keyboard: false,
            backdrop: 'static',
            ariaLabelledBy: 'modal-basic-title',
        };

        if (!params) params = {};

        for (let key in def) {
            if (params[key] == undefined) {
                params[key] = def[key];
            }
        }

        let ref = this.dialog.open(modal, params);

        this.modals[id] = ref;

        return ref.result;
    }

    public closeModal(id) {
        let ref = this.modals[id];
        if (ref) {
            delete this.modals[id];
            ref.close();
        }
    }

    public urltoFile(url, filename, mimeType) {
        return fetch(url)
            .then((res) => res.arrayBuffer())
            .then((buf) => new File([buf], filename, { type: mimeType }));
    }

    public isValidCPF(value: string) {
        if (value.length > 11) {
            if (typeof value !== 'string') {
                return false;
            }

            value = value.replace(/[^\d]+/g, '');

            if (value.length !== 11 || !!value.match(/(\d)\1{10}/)) {
                return false;
            }

            if (
                !value ||
                value.length != 11 ||
                value == '00000000000' ||
                value == '11111111111' ||
                value == '22222222222' ||
                value == '33333333333' ||
                value == '44444444444' ||
                value == '55555555555' ||
                value == '66666666666' ||
                value == '77777777777' ||
                value == '88888888888' ||
                value == '99999999999'
            ) {
                return false;
            }

            const values = value.split('').map((el) => +el);
            const rest = (count) =>
                ((values.slice(0, count - 12).reduce((soma, el, index) => soma + el * (count - index), 0) * 10) % 11) %
                10;

            console.log(rest(10) === values[9] && rest(11) === values[10]);
            return rest(10) === values[9] && rest(11) === values[10];
        }
        return true;
    }

    public isAdmin() {
        if (!this.user().USR_Administrador) {
            this.router.navigate(['/dashboard']);
        }
    }

    public formatCpfCnpj(value) {
        if (value.length === 11) {
            return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else if (value.length === 14) {
            return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        } else {
            return value;
        }
    }

    public md5(text) {
        return MD5('Message').toString();
    }

    public async generateCodeChallenge(codeVerifier) {
        let digest = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(codeVerifier));
        return btoa(String.fromCharCode(...new Uint8Array(digest)))
            .replace(/=/g, '')
            .replace(/\+/g, '-')
            .replace(/\//g, '_');
    }

    public verifyFile(files: any, allowedMimeTypes: string[], maxFileSize?: number) {
        const allowedMimeTypesSet = new Set(allowedMimeTypes);

        if (files[0].size > maxFileSize) {
            this.swal.fire({
                title: 'Atenção',
                text: 'Seu arquivo não pode ser maior do que 40MB',
                icon: 'warning',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: 'Ok',
            });
        } else if (!allowedMimeTypesSet.has(files[0].type)) {
            this.swal.fire({
                title: 'Atenção',
                text: 'O arquivo que você selecionou não é um tipo de arquivo permitido!',
                icon: 'warning',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: 'Ok',
            });
        } else {
            return true;
        }
    }

    public decodeQueryString(query = null): any {
        if (!query) {
            let url = window.location.href.split('?');
            if (url.length > 1) {
                query = url[1];
            }
        }
        let result = {};
        if (query) {
            let queries = query.split('&');
            for (let q of queries) {
                let key = q.split('=');
                result[key[0]] = key[1];
            }
        }
        return result;
    }

    showModalInfo(modalName: string) {
        $('#modal-info-' + modalName).modal('show');
    }

    closeModalInfo(modalName: string) {
        $('#modal-info-' + modalName).modal('hide');
    }

    public handleNavigationBackButton() {
        window.history.back();
    }

    formatDistanceToNow(date: Date): string {
        return formatDistanceToNow(new Date(date), {
            addSuffix: true,
            locale: ptBR,
        });
    }

    saveFile(
        data: ArrayBuffer | Blob, 
        fileName: string, 
        type = 'application/pdf'
    ) {
        let blob: Blob;

        if (data instanceof Blob) {
            blob = data;
        } 
        else {
            blob = new Blob([data], { type });
        }

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(link.href);
    }

    getArray(n: number): Array<number> {
        return Array(n);
    }

    public get govBr() {
        return environment.production || environment.projectURL == 'https://web.hmlproto.pmsga.ce.gov.br';
    }

    public get showForms() {
        return environment.production;
    }

    arrayBufferToBase64(arrayBuffer: ArrayBuffer): string {
        // Converte o ArrayBuffer para uma string de caracteres
        const byteArray = new Uint8Array(arrayBuffer);
        let binary = '';
        
        for (let i = 0; i < byteArray.length; i++) {
          binary += String.fromCharCode(byteArray[i]);
        }
      
        // Converte a string binária para Base64
        return btoa(binary);
    }
}
